import { create } from 'zustand'
import { Plane, Vector3 } from 'three'
import { v4 as uuidv4 } from 'uuid'
import { persist } from 'zustand/middleware'
import { ISOMETRIC_CONSTS } from '@/lib/workers/constants'
import { PlaneVisualizer } from '@/lib/cad/PlaneVisualizer'
import { initPlaneObject } from '../pages/CADPage/components/CADModel/CrossSectionControls'

const LOCAL_STORAGE_KEY = 'q20-cad-page-store'

export type Explosions = {
  [partId: string]: {
    position: [number, number, number]
    originalPosition: [number, number, number]
  }
}

export type SaveableExplosions = {
  id: string
  cadId: string
  explosions: Explosions
}

export enum SectionState {
  PREINIT = 'preinit',
  INIT = 'init',
  PREENABLE = 'preenable',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  RESET = 'reset',
}

export enum ExplosionsState {
  DISABLED = 'DISABLED',
  INIT_CONTROLS = 'INIT_CONTROLS',
  ENABLED = 'ENABLED',
  DRAGGING = 'DRAGGING',
}

export enum CONTROLS {
  TRACKBALL_CONTROLS = 1,
  CAMERA_CONTROLS = 2,
}

export enum WAND {
  TRANSPARENCY = 'TRANSPARENCY',
}

export type WandButtonState = WAND | string | null

export type RenderMode = 'full-color' | 'outline'

export type CADPageState = StateVariables & StateActions

export type StateVariables = {
  /* Camera & Scene */
  cameraAspect: number
  cameraPosition: number[]
  cameraFov: number
  cameraZoom: number
  cameraQuaternion: number[]
  cameraUp: number[]
  cameraNear: number | null
  cameraFar: number | null
  createdViews: string[]
  boundsMax: number | null
  sceneCenter: Vector3 | null
  target: number[]
  controlsId: number
  fitting: boolean
  /* Selected Parts */
  selectedParts: string[]
  hiddenParts: string[]
  highlightedPartUUID: string | null
  /* Wand Tool */
  colorMap: {
    [key: string]: number
  }
  transparentParts: string[]
  /* Explosions */
  isExplosionLinesEnabled: boolean
  wandSelected: WandButtonState
  explosions: Explosions
  explosionsState: ExplosionsState
  loadedExplosion: string
  savedExplosions: SaveableExplosions[]
  point: [number, number, number]
  /* Cross Sections */
  sectionState: SectionState
  clippingPlanes: Plane[]
  exclusiveCrossSectionMap: string[][]
  crossSectionMap: string[][]
  planeObject: PlaneVisualizer
  rotateEnabled: boolean
  translateEnabled: boolean
  /* CAD Page */
  isEditModeActive: boolean
  showAxis: boolean
  isRotating: boolean
  isDragging: boolean
  renderMode: RenderMode
  loadingProgress: number
  createViewButtonHover: boolean
  operationStep?: {
    documentId: string
    stepId: string
    selectFromCad: boolean
    isActive: boolean
  } | null
  filterScreenshotsByDocumentPageId?: string | null
  subAssemblyDocumentPageId?: string | null
  hasDocumentLoaded: boolean
}

const initialState: StateVariables = {
  /* Camera & Scene */
  cameraAspect: 1,
  cameraPosition: [],
  cameraFov: ISOMETRIC_CONSTS.FOV,
  cameraZoom: ISOMETRIC_CONSTS.ZOOM,
  cameraQuaternion: ISOMETRIC_CONSTS.QUATERNION,
  cameraUp: [0, 1, 0],
  cameraNear: null,
  cameraFar: null,
  createdViews: [],
  boundsMax: null,
  sceneCenter: null,
  target: [0, 0, 0],
  controlsId: CONTROLS.TRACKBALL_CONTROLS,
  fitting: false,
  /* Selected Parts */
  selectedParts: [],
  highlightedPartUUID: null,
  hiddenParts: [],
  /* Wand Tool*/
  transparentParts: [],
  colorMap: {},
  wandSelected: null,
  /* Explosions */
  isExplosionLinesEnabled: false,
  explosionsState: ExplosionsState.DISABLED,
  explosions: {},
  savedExplosions: [],
  loadedExplosion: '',
  point: [0, 0, 0],
  /* Cross Sections */
  sectionState: SectionState.PREINIT,
  clippingPlanes: [],
  exclusiveCrossSectionMap: [],
  crossSectionMap: [],
  planeObject: initPlaneObject(),
  rotateEnabled: true,
  translateEnabled: true,
  /* CAD Page */
  showAxis: false,
  isRotating: false,
  isDragging: false,
  isEditModeActive: false,
  renderMode: 'outline',
  operationStep: null,
  loadingProgress: 0,
  createViewButtonHover: false,
  filterScreenshotsByDocumentPageId: null,
  subAssemblyDocumentPageId: null,
  hasDocumentLoaded: false,
}

export type StateActions = {
  /* CAD Page */
  setState: (state: Partial<CADPageState>) => void
  getState: () => CADPageState
  resetEditMode: () => void
  toggleAxis: () => void
  isHidden: (uuid: string) => boolean
  setLoadingProgress: (progress: number) => void
  setCreateViewButtonHover: (hover: boolean) => void
  reset: () => void
  addCreatedView: (viewId: string) => void
  /* Wand Tool*/
  isColored: (partId: string) => boolean
  isTransparent: (partId: string) => boolean
  isColor: (partId: string, color: string) => boolean
  updateAppearance: (partId: string, appearance: string) => void
  setWandSelected: (wandState: WandButtonState) => void
  /* Selected Parts */
  pushSelectedPart: (uuid: string) => void
  /* Explosions */
  disableExplosions: () => void
  setExplosion: (
    instanceName: string,
    oldPositions: [number, number, number],
    newPosition: [number, number, number],
  ) => void
  /* Cross Sections */
  toggleSectioning: () => void
  disableSectioning: () => void
  saveNewExplosion: (cadId: string) => void
  toggleExplosions: () => void
}

export const useCADPageStore = create<CADPageState>()(
  persist(
    (set, get) => ({
      ...initialState,

      reset: () => {
        set(({ savedExplosions }) => ({
          ...initialState,
          savedExplosions,
          createdViews: [],
          planeObject: initPlaneObject(),
        }))
      },

      setState: (state: Partial<CADPageState>) => {
        set(state)
      },
      resetEditMode: () => {
        const prevOperationStep = get().operationStep
        set({
          highlightedPartUUID: null,
          isEditModeActive: false,
          operationStep: prevOperationStep
            ? {
                ...prevOperationStep,
                selectFromCad: false,
              }
            : null,
          selectedParts: [],
          explosionsState: ExplosionsState.DISABLED,
          wandSelected: null,
        })
        get().disableSectioning()
      },
      getState: () => get(),
      toggleAxis: () => set((state) => ({ showAxis: !state.showAxis })),
      setLoadingProgress: (progress: number) =>
        set({ loadingProgress: progress }),
      setCreateViewButtonHover: (hover: boolean) => {
        set({ createViewButtonHover: hover })
      },
      addCreatedView: (viewId) => {
        set((state) => {
          state.createdViews.push(viewId)
          return state
        })
      },

      /*
       *
       * Selected Parts
       *
       */
      pushSelectedPart: (uuid: string) => {
        set((state) => ({
          selectedParts: [...new Set([...state.selectedParts, uuid])],
        }))
      },
      isHidden: (partId: string) => {
        return get().hiddenParts.includes(partId)
      },

      /*
       *
       * Explosions
       *
       */
      setExplosion: (
        partId: string,
        oldPositions: [number, number, number],
        newPosition: [number, number, number],
      ) =>
        set((state) => {
          const explosions = { ...state.explosions }
          if (explosions[partId]) {
            explosions[partId].position = newPosition
          } else {
            explosions[partId] = {
              position: newPosition,
              originalPosition: oldPositions,
            }
          }
          return { explosions }
        }),
      saveNewExplosion: (cadId: string) => {
        set((state) => {
          const newExplosions = JSON.parse(JSON.stringify(state.explosions))
          const newSaveableExplosions: SaveableExplosions = {
            cadId,
            id: uuidv4(),
            explosions: newExplosions,
          }
          return {
            savedExplosions: [...state.savedExplosions, newSaveableExplosions],
            loadedExplosion: newSaveableExplosions.id,
          }
        })
      },
      disableExplosions: () => {
        set(() => ({ explosionsState: ExplosionsState.DISABLED }))
      },
      toggleExplosions: () => {
        set(({ explosionsState }) => {
          if (explosionsState === ExplosionsState.DISABLED) {
            return {
              explosionsState: ExplosionsState.INIT_CONTROLS,
            }
          } else {
            return {
              explosionsState: ExplosionsState.DISABLED,
            }
          }
        })
      },

      /*
       *
       * Cross Sections
       *
       */
      disableSectioning: () => {
        set((state) => {
          if (state.sectionState === SectionState.ENABLED) {
            return { sectionState: SectionState.DISABLED }
          }
          return state
        })
      },
      toggleSectioning: () => {
        const { PREINIT, INIT, PREENABLE, DISABLED, RESET } = SectionState
        set(({ sectionState }) => {
          if (sectionState === PREINIT || sectionState === RESET) {
            return { sectionState: INIT, isEditModeActive: true }
          } else if (sectionState === DISABLED) {
            return { sectionState: PREENABLE, isEditModeActive: true }
          } else {
            return { sectionState: DISABLED, isEditModeActive: false }
          }
        })
      },

      /*
       *
       * Wand Tool
       *
       */
      setWandSelected: (wandState: WandButtonState) => {
        const currentState = get().wandSelected
        if (currentState === wandState) set({ wandSelected: null })
        else set({ wandSelected: wandState })
      },
      updateAppearance: (partId: string, appearance: string) => {
        set((state) => {
          let transparentParts = state.transparentParts
          let colorMap = state.colorMap

          if (appearance === WAND.TRANSPARENCY) {
            // If part is already transparent, remove transparency
            if (state.isTransparent(partId)) {
              transparentParts = transparentParts.filter(
                (part) => part !== partId,
              )
            } else {
              // If part is not transparent, make it transparent and remove color
              transparentParts = [...new Set([...transparentParts, partId])]
              colorMap = Object.fromEntries(
                Object.entries(colorMap).filter(([key]) => key !== partId),
              )
            }
          } else {
            // Remove part from transparent list if it exists
            if (state.isTransparent(partId)) {
              transparentParts = transparentParts.filter(
                (part) => part !== partId,
              )
            }

            // If part is already this color, remove color
            if (state.isColor(partId, appearance)) {
              colorMap = Object.fromEntries(
                Object.entries(colorMap).filter(([key]) => key !== partId),
              )
            } else {
              colorMap = {
                ...colorMap,
                [partId]: parseInt(appearance.slice(1), 16),
              }
            }
          }

          return { transparentParts, colorMap }
        })
      },
      isColored: (partId: string) => {
        return get().colorMap[partId] !== undefined
      },
      isColor: (partId: string, color: string) => {
        return get().colorMap[partId] === parseInt(color.slice(1), 16)
      },
      isTransparent: (partId: string) => {
        return get().transparentParts.includes(partId)
      },
    }),

    /* Persist only Explosions */
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({ savedExplosions: state.savedExplosions }),
    },
  ),
)
