import { useMemo, forwardRef, useCallback, useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Spinner } from '@/components/ui/spinner'
import { useListCads } from '@/services/queries/cads'
import { cn } from '@/utils'
import { getLatestCadVersion } from '@/services/queries/cad_versions'

export const SelectCADDropdown = forwardRef<
  any,
  {
    onChange: (value: string) => void
    onCadSelect?: () => void
    value?: string
    projectId?: string
  }
>(
  (
    { onChange, onCadSelect, value, projectId },
    // @ts-expect-error ref is not used but it's required for forwardRef
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const { isFetching, data: cads } = useListCads({ projectId })
    const [latestCadVersions, setLatestCadVersions] = useState<Array<any>>([])

    useEffect(() => {
      if (cads && cads.length > 0) {
        Promise.all(
          cads.map((cad) =>
            cad?.id ? getLatestCadVersion({ cadId: cad.id }) : null,
          ),
        )
          .then((cadVersions) => {
            const updatedCadVersions = cadVersions.map((cadVersion, i) => ({
              ...cadVersion,
              cadName: cads[i].name,
              cadId: cads[i].id,
            }))
            setLatestCadVersions(updatedCadVersions)
          })
          .catch(() => {
            console.log('Failed to get latest cad versions')
          })
      }
    }, [cads])

    const sortedCads = useMemo(() => {
      return latestCadVersions?.sort() || []
    }, [latestCadVersions])

    const onValueChangeHandler = useCallback(
      (val: string) => {
        onCadSelect?.()
        onChange(val)
      },
      [onCadSelect],
    )

    return (
      <Select
        disabled={!projectId}
        onValueChange={onValueChangeHandler}
        value={value}
      >
        <SelectTrigger
          id="project-id"
          className={cn('border', {
            'border-transparent': !value,
            'border-blue-500': !!value,
            'text-blue-500': !!value,
          })}
        >
          <SelectValue placeholder="Select a CAD" />
        </SelectTrigger>
        <SelectContent className="max-h-72">
          {sortedCads?.map((cad) => (
            <SelectItem key={cad.id} value={cad.cadId as string}>
              {cad.cadName}
            </SelectItem>
          ))}
          {isFetching && <Spinner />}
        </SelectContent>
      </Select>
    )
  },
)
SelectCADDropdown.displayName = 'SelectCADDropdown'
