import { mergeBufferGeometries } from 'three-stdlib'
import {
  Object3D,
  BufferGeometry,
  Group,
  LineSegments,
  Material,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  EdgesGeometry,
} from 'three'

import { frontMaterialProperties, backMaterialProperties } from './materials'
import {
  BACK_RENDER_ORDER,
  FRONT_RENDER_ORDER,
  WIRE_RENDER_ORDER,
} from '@/pages/CADPage/constants'
import { ASMTreeNode } from '@/state'
import { GLTFObject } from './GLTFObject'

/**
 * Merges all part meshes of the given Object3D that share the same material into a single mesh.
 * This function traverses the object hierarchy, collects geometries by their materials,
 * and attempts to merge them. If successful, it replaces the original meshes with a new
 * merged mesh and disposes of the old geometries and materials.
 *
 * @param obj - The Object3D instance whose part meshes are to be merged.
 * @returns return number of meshes merged
 */
export function mergePartMeshes(obj: Object3D): number {
  let nMeshes = 0
  if (obj.userData?.isMerged) return nMeshes

  const materialGeometryMap = new Map<
    MeshStandardMaterial,
    { geometries: BufferGeometry[]; meshes: Mesh[] }
  >()

  obj.traverse((child) => {
    if (child instanceof Mesh) {
      nMeshes++
      const material = child.material as MeshStandardMaterial
      if (materialGeometryMap.has(material)) {
        materialGeometryMap.get(material)?.geometries.push(child.geometry)
        materialGeometryMap.get(material)?.meshes.push(child)
      } else {
        materialGeometryMap.set(child.material, {
          geometries: [child.geometry],
          meshes: [child],
        })
      }
    }
  })

  // Get the base name of the object
  const partBaseName = obj?.name || obj?.uuid
  if (!obj.name) console.warn('Object has no name, using UUID: ', obj.toJSON())

  // Attempt to merge geometries of the same material
  let nMeshesMerged = 0
  let index = 0
  materialGeometryMap.forEach(({ geometries, meshes }, material) => {
    const mergedGeometry = mergeBufferGeometries(geometries)
    if (!mergedGeometry) {
      console.error('Failed to merge geometries: ', obj.name)
      return 0
    }

    // Create front side mesh with merged geometry
    material.setValues(frontMaterialProperties)
    material.needsUpdate = true
    const mergedMesh = new Mesh(mergedGeometry, material)
    mergedMesh.renderOrder = FRONT_RENDER_ORDER
    const suffix = nMeshesMerged > 0 ? `_${nMeshesMerged}` : ''
    mergedMesh.name = `_${partBaseName}${suffix}`
    nMeshesMerged++
    obj.add(mergedMesh)
    obj.userData.isMerged = true

    if (index === 0) {
      index++
      const partGeometry: BufferGeometry[] = []
      materialGeometryMap.forEach(({ geometries }) => {
        partGeometry.push(...geometries)
      })

      const backGeometry = mergeBufferGeometries(partGeometry)
      if (!backGeometry) {
        console.error('Failed to merge back geometries: ', obj.name)
        return 0
      }

      // Create back side mesh with merged geometry
      const backSideMaterial = new MeshBasicMaterial({
        color: material.color.getHex(),
        ...backMaterialProperties,
      })
      const backMesh = new Mesh(backGeometry, backSideMaterial)
      backMesh.renderOrder = BACK_RENDER_ORDER
      obj.add(backMesh)
    }

    // Clean up old meshes
    meshes.forEach((mesh) => {
      obj.remove(mesh)
      mesh.geometry.dispose()
      const material = mesh.material as Material
      material.dispose()
    })
  })

  return nMeshes
}

export function buildPartWireframe(this: GLTFObject, obj: Object3D) {
  const lineSegmentGroup = new Group()
  lineSegmentGroup.name = `_wiregroup_${obj.name}`
  lineSegmentGroup.frustumCulled = true
  lineSegmentGroup.renderOrder = WIRE_RENDER_ORDER
  const lineGeometries: BufferGeometry[] = []

  obj.traverse((child) => {
    if (child.type === 'Mesh') {
      const mesh = child as Mesh
      const edges = new EdgesGeometry(mesh.geometry, 180)
      lineGeometries.push(edges)
    }
  })

  // Merge all line geometries
  if (lineGeometries.length > 0) {
    const mergedGeometry = mergeBufferGeometries(lineGeometries)
    if (!mergedGeometry) {
      console.error('Failed to merge wireframe geometries: ', obj.name)
      return lineSegmentGroup
    }

    const mergedLine = new LineSegments(mergedGeometry, this.lineMaterial)
    mergedLine.name = `_wireframe_${obj.name}`
    mergedLine.frustumCulled = true
    mergedLine.renderOrder = WIRE_RENDER_ORDER
    lineSegmentGroup.add(mergedLine)

    // Dispose of the old geometries
    lineGeometries.forEach((geo) => geo.dispose())
  }

  return lineSegmentGroup
}

export function isLeafNode(obj: Object3D): boolean {
  return obj.children.every((c) => c instanceof Mesh && c.children.length === 0)
}

export function isLeaf(node: ASMTreeNode) {
  return node.children.length === 0
}
