import {
  TrackballControls,
  CameraControls as YomotsuControls,
} from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import CameraControlsImpl from 'camera-controls'
import { PerspectiveCamera, Vector3 } from 'three'
import { useShallow } from 'zustand/react/shallow'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { TrackballControls as TrackballControlsImpl } from 'three-stdlib'

import { useCameraControls } from '../../hooks'
import { useCADPageStore, CONTROLS } from '@/state'
import { useCADQuery } from '@/services/queries/cads'

export const CameraControls = () => {
  const {
    data: { gltf },
  } = useCADQuery()
  const { fitToSphere } = useCameraControls()
  const camera = useThree((state) => state.camera)
  const controls = useThree((state) => state.controls)
  const targetVector = useMemo(() => new Vector3(), [])
  const cameraControlsRef = useRef<CameraControlsImpl | null>(null)
  const target = useCADPageStore(useShallow((state) => state.target))
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const controlsId = useCADPageStore(useShallow((state) => state.controlsId))

  /*
   * listen for
   * controls
   */
  useEffect(() => {
    const model = gltf?.scene.children[0]

    if (model) {
      fitToSphere(model)
        .then()
        .catch((e) => console.error(e))
    }
  }, [controls, gltf, fitToSphere])

  const updateCameraState = useCallback(() => {
    if (camera instanceof PerspectiveCamera) {
      const cameraState = {
        cameraAspect: camera.aspect,
        cameraPosition: camera.position.toArray(),
        cameraFov: camera.fov,
        cameraZoom: camera.zoom,
        cameraQuaternion: camera.quaternion.toArray(),
        cameraUp: camera.up.toArray(),
      }

      if (controls instanceof TrackballControlsImpl) {
        setState({
          target: controls.target.toArray(),
          ...cameraState,
        })
      } else if (controls instanceof CameraControlsImpl) {
        setState({
          target: controls.getTarget(targetVector).toArray(),
          ...cameraState,
        })
      }
    }
  }, [camera, controls, setState, targetVector])

  if (controlsId === CONTROLS.CAMERA_CONTROLS) {
    return (
      <YomotsuControls
        makeDefault
        ref={cameraControlsRef}
        camera={camera}
        enabled={true}
        onChange={updateCameraState}
        minPolarAngle={0}
        maxPolarAngle={Math.PI}
        minDistance={0.0000001}
        maxDistance={100}
        dampingFactor={0}
        dollySpeed={2.5}
        truckSpeed={2.5}
      />
    )
  } else if (controlsId === CONTROLS.TRACKBALL_CONTROLS) {
    return (
      <TrackballControls
        makeDefault // ensures these are disabled when other controls are active
        target={[target[0], target[1], target[2]]}
        onChange={updateCameraState}
        zoomSpeed={2.5}
        dynamicDampingFactor={0}
        staticMoving={true}
        rotateSpeed={2.5}
      />
    )
  }
}
