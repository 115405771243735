import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Loading } from '@/components/ui/loading'
import { Files } from '@/components/ui/file'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'

import { useCreateCadVersionMutation } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'

type FormInputs = {
  comment: string
  files: File[]
}

const formSchema = z.object({
  files: z.array(z.any()).min(1, {
    message: 'Upload at least 1 CAD file.',
  }),
  comment: z.string(),
})

export const UpdateCadVersionButton = () => {
  const { isLoading, data } = useCADQuery()
  const { mutate, isPending } = useCreateCadVersionMutation()
  const form = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      comment: '',
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (!data?.cad) {
      return
    }
    mutate({
      cadId: data?.cad.id as string,
      comment: values.comment,
      file: values.files[0],
    })
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Dialog>
      <DialogTrigger
        disabled={!data.isLatestCadVersion}
        className="w-full inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary-50 text-white shadow hover:opacity-90 h-9 px-4 py-2"
        style={{ maxWidth: '220px' }}
      >
        Update CAD
      </DialogTrigger>
      <DialogContent ariaLabel="Update CAD version dialog">
        <DialogHeader>
          <DialogTitle>Create new version</DialogTitle>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <Files
                onChange={(files) => {
                  form.setValue('files', files)
                }}
                files={form.watch('files')}
                validateFile={(file) => {
                  form.setError('files', {})
                  const validExtentions = ['step', 'stp']
                  const tok = file.name.toLowerCase().split('.')
                  const ext = tok[tok.length - 1]

                  const hasValidFileExtention = validExtentions.includes(ext)
                  if (!hasValidFileExtention) {
                    form.setError('files', {
                      type: 'extention',
                      message: `Only files with the following extensions are allowed: ${validExtentions.join(
                        ', ',
                      )}.`,
                    })
                  }

                  const isValid = hasValidFileExtention

                  return isValid
                }}
                error={form.formState.errors.files?.message}
              />
              <FormField
                control={form.control}
                name="comment"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Comment</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                disabled={isPending || !data.isLatestCadVersion}
              >
                {isPending ? (
                  <div className="flex items-center space-x-2">
                    <span>Uploading</span>
                    <Loading />
                  </div>
                ) : (
                  'Create Version'
                )}
              </Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
