import { useEffect, useRef, useState } from 'react'

import { Input } from '@/components/ui/input'
import { cn } from '@/utils'
import { useToast } from '../ui/use-toast'

type EditableFieldProps = {
  value: string
  onSaveHandler: (value: string) => void
  inputClassNames?: string
  textClassNames?: string
  shouldStopPropagation?: boolean
  dataTestId?: string
}
export const EditableField = ({
  value,
  onSaveHandler,
  inputClassNames,
  textClassNames,
  shouldStopPropagation,
  dataTestId,
}: EditableFieldProps) => {
  const { toast } = useToast()
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current && isEditing) {
      inputRef.current.focus()
    }
  }, [inputRef, isEditing])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <span
      data-testid={dataTestId}
      className="text-ellipsis overflow-hidden max-w-[205px] whitespace-nowrap"
    >
      {isEditing ? (
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onMouseDown={(e) => {
            if (shouldStopPropagation) {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }
          }}
          className={cn('h-auto p-1', inputClassNames)}
          onBlur={() => {
            setIsEditing(false)
            if (!inputValue) {
              toast({
                title: `Can't update ${value}`,
                description: 'This value cannot be empty',
                variant: 'destructive',
              })
              setInputValue(value)
              return
            }
            onSaveHandler(inputValue)
          }}
        />
      ) : (
        <span
          className={cn('cursor-pointer', textClassNames)}
          onDoubleClick={() => setIsEditing(true)}
          onMouseDown={(e) => {
            if (shouldStopPropagation) {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }
          }}
        >
          {inputValue}
        </span>
      )}
    </span>
  )
}
