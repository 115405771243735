import { useLocation } from 'wouter'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useShallow } from 'zustand/react/shallow'
import { Skeleton } from '@/components/ui/skeleton'

import { QUERY_KEYS as CAD_QUERY_KEYS } from '@/services/queries/cads'
import { useCADQuery } from '@/services/queries/cads'
import { useCadPageParams } from '@/pages/CADPage/hooks'

import { UpdateCadVersionButton } from '../UpdateCadVersionButton'
import queryClient from '@/queryClient'
import { useCADPageStore } from '@/state'

export const CADVersionDropdown = () => {
  const { projectId, cv: cadVersionParam } = useCadPageParams()
  const resetCadPageStore = useCADPageStore(useShallow((state) => state.reset))

  const [, setLocation] = useLocation()

  const { isLoading, data } = useCADQuery()
  const cadVersions = data?.cadVersions || []
  const cadVersion = data?.version

  const defaultVersion =
    cadVersionParam || (cadVersion?.id ? `${cadVersion.id}` : undefined)

  if (isLoading) {
    return (
      <div className="flex flex-col items-center space-y-2 py-7">
        <Skeleton className="w-32 h-10" />
      </div>
    )
  }

  return (
    <div
      className="flex flex-col items-center space-y-2 py-7 rounded-xl border border-primary-30"
      style={{ backgroundColor: '#DEE6FA' }}
    >
      <Select
        defaultValue={defaultVersion}
        onValueChange={(selectedVersion) => {
          queryClient.removeQueries({
            queryKey: [CAD_QUERY_KEYS.GLTF],
          })
          resetCadPageStore()
          setLocation(
            `/p/${projectId}/document/${data.documentVersion?.document}/cad/?cv=${selectedVersion}`,
          )
        }}
      >
        <SelectTrigger
          className="w-full h-10 rounded-full"
          style={{ maxWidth: '220px' }}
        >
          <SelectValue placeholder="Version" />
        </SelectTrigger>
        <SelectContent>
          {cadVersions.map((version) => (
            <SelectItem key={version.id} value={`${version.id}`}>
              V{version.version_number}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <UpdateCadVersionButton />
    </div>
  )
}
