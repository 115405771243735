import {
  ShapesIcon,
  LinesIcon,
  HardwareSpecificsIcon,
  CircleIcon,
  TriangleIcon,
  SquareIcon,
  TorxIcon,
  PhillipsIcon,
  HexIcon,
  PozidrivIcon,
  CheckmarkIcon,
  XIcon,
  FireHazardIcon,
  WarningIcon,
  LineIcon,
  ArrowIcon,
  IconProps,
  ArrowWithBottomTextIcon,
  ArrowWithMiddleTextIcon,
} from '@/components/icons/AnnotationsIcons'
import { IconSize } from './AnnotationsToolbarMenuItem'

export type AnnotationsMenuItem = {
  id: string
  size?: IconSize
  strokeColor?: string
  Icon: (props: IconProps) => JSX.Element
}

export type AnnotationsMenu = {
  menuItems: Array<AnnotationsMenuItem>
  TriggerIcon: (props: { className?: string }) => JSX.Element
  id: string
  columns: number
  colorOptionsEnabled?: boolean
  tooltipContent: string
  label: string
}

export const SHAPES_MENU_ITEMS: Array<AnnotationsMenuItem> = [
  {
    id: 'circle',
    size: 'small',
    Icon: CircleIcon,
  },
  {
    id: 'circle',
    size: 'medium',
    Icon: CircleIcon,
  },
  {
    id: 'circle',
    size: 'large',
    Icon: CircleIcon,
  },
  {
    id: 'triangle',
    size: 'small',
    Icon: TriangleIcon,
  },
  {
    id: 'triangle',
    size: 'medium',
    Icon: TriangleIcon,
  },
  {
    id: 'triangle',
    size: 'large',
    Icon: TriangleIcon,
  },
  {
    id: 'square',
    size: 'small',
    Icon: SquareIcon,
  },
  {
    id: 'square',
    size: 'medium',
    Icon: SquareIcon,
  },
  {
    id: 'square',
    size: 'large',
    Icon: SquareIcon,
  },
]

export const LINES_MENU_ITEMS: Array<AnnotationsMenuItem> = [
  {
    id: 'line',
    Icon: LineIcon,
  },
  {
    id: 'arrow',
    Icon: ArrowIcon,
  },
  {
    id: 'arrow-with-text--middle',
    Icon: ArrowWithMiddleTextIcon,
  },
  {
    id: 'arrow-with-text--bottom',
    Icon: ArrowWithBottomTextIcon,
  },
]

export const HARDWARE_SPECIFICS_MENU_ITEMS: Array<AnnotationsMenuItem> = [
  {
    id: 'torx',
    strokeColor: '#1E1E1E',
    size: 8,
    Icon: TorxIcon,
  },
  {
    id: 'phillips',
    strokeColor: '#1E1E1E',
    size: 8,
    Icon: PhillipsIcon,
  },
  {
    id: 'hex',
    strokeColor: '#1E1E1E',
    size: 8,
    Icon: HexIcon,
  },
  {
    id: 'pozidriv',
    strokeColor: '#1E1E1E',
    size: 8,
    Icon: PozidrivIcon,
  },
  {
    id: 'checkmark',
    strokeColor: '#008D5A',
    size: 10,
    Icon: CheckmarkIcon,
  },
  {
    id: 'x',
    strokeColor: '#E64343',
    size: 10,
    Icon: XIcon,
  },
  {
    id: 'fire_hazard',
    strokeColor: '#E64343',
    size: 10,
    Icon: FireHazardIcon,
  },
  {
    id: 'warning',
    strokeColor: '#D0CA36',
    size: 10,
    Icon: WarningIcon,
  },
]

export const SHAPES_MENU: AnnotationsMenu = {
  menuItems: SHAPES_MENU_ITEMS,
  TriggerIcon: ShapesIcon,
  id: 'shapes',
  columns: 3,
  colorOptionsEnabled: true,
  tooltipContent: 'Shapes',
  label: 'Shapes',
}

export const LINES_MENU: AnnotationsMenu = {
  menuItems: LINES_MENU_ITEMS,
  TriggerIcon: LinesIcon,
  id: 'lines',
  columns: 4,
  colorOptionsEnabled: true,
  tooltipContent: 'Lines',
  label: 'Arrows',
}

export const HARDWARE_SPECIFICS_MENU: AnnotationsMenu = {
  menuItems: HARDWARE_SPECIFICS_MENU_ITEMS,
  TriggerIcon: HardwareSpecificsIcon,
  id: 'specifics',
  columns: 4,
  tooltipContent: 'Hardware Specifics',
  label: 'Icons',
}

export const DEFAULT_MENUS: Array<AnnotationsMenu> = [
  SHAPES_MENU,
  LINES_MENU,
  HARDWARE_SPECIFICS_MENU,
]
export const ALL_ANNOTATIONS_ICONS = [
  ...SHAPES_MENU_ITEMS,
  ...HARDWARE_SPECIFICS_MENU_ITEMS,
  ...LINES_MENU_ITEMS,
]
