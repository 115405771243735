import { useEffect, useState, useRef } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import items from './files.json'
import Carousel from '../Carousel'
import { Button } from '@/components/ui/button'

const SELF_ONBOARDING_COMPLETED_KEY = 'self-onboarding-completed'

export const SelfOnboardingModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const carouselRef = useRef<any>(null)
  const [currentStep, setCurrentStep] = useState(0)

  const handleNext = () => {
    carouselRef.current?.nextSlide()
  }

  const handlePrev = () => {
    carouselRef.current?.prevSlide()
  }

  useEffect(() => {
    const tutorialCompleted = localStorage.getItem(
      SELF_ONBOARDING_COMPLETED_KEY,
    )
    if (!tutorialCompleted) {
      setIsOpen(true)
    }
  }, [])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isDialogOpen) => {
        setIsOpen(isDialogOpen)
        if (!isDialogOpen) {
          localStorage.setItem(SELF_ONBOARDING_COMPLETED_KEY, 'true')
        }
      }}
    >
      <DialogContent
        withCloseButton={true}
        className="h-[700px]"
        ariaLabel="Confirmation dialog"
      >
        <div className="flex flex-col">
          <DialogTitle className="text-center">
            <h3 className="text-lg font-normal text-blue-500">
              <span className="bg-blue-100 rounded-lg px-3 py-1">
                Welcome to Quarter20
              </span>
            </h3>
          </DialogTitle>
          <div className="flex flex-1 flex-col mt-4 justify-between">
            <div className="max-w-2xl mx-auto">
              <Carousel
                ref={carouselRef}
                loop={false}
                showControls={false}
                onStepChange={setCurrentStep}
              >
                {items.map((item, i) => (
                  <div key={i} className="flex items-center justify-center">
                    {i === currentStep ? (
                      <img src={item.media} className="w-[700px] h-auto" />
                    ) : (
                      <div className="w-[700px] h-[540px]" />
                    )}
                  </div>
                ))}
              </Carousel>

              <div className="mt-4 text-center w-full flex justify-around">
                {currentStep > 0 ? (
                  <Button
                    onClick={() => handlePrev()}
                    className="p-2 rounded-lg border-none w-[80px]"
                  >
                    Previous
                  </Button>
                ) : (
                  <div className="w-[80px]" />
                )}
                {currentStep !== items.length - 1 ? (
                  <Button
                    onClick={() => handleNext()}
                    className="p-2 rounded-lg border-none w-[80px]"
                  >
                    Next
                  </Button>
                ) : (
                  <div className="w-[80px]" />
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
