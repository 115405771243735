'use client'

import {
  useEffect,
  useRef,
  useState,
  Children,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Tabs, TabsTrigger, TabsList } from '@/components/ui/tabs'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { cn } from '@/utils'
import { Button } from '../ui/button'

interface CarouselProps {
  children: ReactNode[]
  interval?: number
  loop?: boolean
  animate?: boolean
  showControls?: boolean
  showIndicators?: boolean
  onStepChange?: (step: number) => void
}

const Carousel = forwardRef<any, CarouselProps>(
  (
    {
      children,
      interval,
      loop = true,
      animate = true,
      showControls = true,
      showIndicators = true,
      onStepChange,
    },
    ref,
  ) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const totalSlides = Children.count(children)
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null)

    useEffect(() => {
      if (onStepChange) {
        onStepChange(activeIndex)
      }
    }, [activeIndex, onStepChange])

    useEffect(() => {
      if (interval && interval > 0) {
        timerRef.current = setInterval(() => {
          setActiveIndex((prev) =>
            loop
              ? (prev + 1) % totalSlides
              : Math.min(prev + 1, totalSlides - 1),
          )
        }, interval)
      }

      return () => {
        if (timerRef.current) clearInterval(timerRef.current)
      }
    }, [interval, totalSlides, loop])

    const goToSlide = (index: number) => {
      setActiveIndex(index)

      if (interval && timerRef.current) {
        clearInterval(timerRef.current)
        timerRef.current = setInterval(() => {
          setActiveIndex((prev) =>
            loop
              ? (prev + 1) % totalSlides
              : Math.min(prev + 1, totalSlides - 1),
          )
        }, interval)
      }
    }

    const nextSlide = () => {
      setActiveIndex((prev) =>
        loop ? (prev + 1) % totalSlides : Math.min(prev + 1, totalSlides - 1),
      )
    }

    const prevSlide = () => {
      setActiveIndex((prev) =>
        loop ? (prev - 1 + totalSlides) % totalSlides : Math.max(prev - 1, 0),
      )
    }

    useImperativeHandle(ref, () => ({
      goToSlide,
      nextSlide,
      prevSlide,
    }))

    return (
      <Tabs
        value={String(activeIndex)}
        onValueChange={(value) => goToSlide(Number(value))}
        className="relative w-full mx-auto"
      >
        <div className="relative w-full overflow-hidden rounded-lg">
          <div
            className={cn(
              'flex w-full',
              animate ? 'transition-transform duration-700 ease-in-out' : '',
            )}
            style={{
              transform: `translateX(-${activeIndex * 100}%)`,
            }}
          >
            {Children.map(children, (child, index) => (
              <div
                key={index}
                className="w-full shrink-0 flex justify-center items-center"
              >
                {child}
              </div>
            ))}
          </div>
        </div>

        {showIndicators && (
          <TabsList className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {children.map((_, index) => (
              <TabsTrigger
                key={index}
                value={String(index)}
                className={cn(
                  'w-3 h-3 rounded-full transition bg-gray-400 hover:bg-gray-600 p-0 border-none bg-gray-400',
                  {
                    'bg-gray-900': activeIndex === index,
                  },
                )}
              />
            ))}
          </TabsList>
        )}

        {showControls && (
          <>
            {!loop && activeIndex > 0 && (
              <Button
                onClick={() => prevSlide()}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-900"
              >
                <ChevronLeftIcon />
              </Button>
            )}

            {!loop && activeIndex < totalSlides - 1 && (
              <Button
                onClick={() => nextSlide()}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-900"
              >
                <ChevronRightIcon />
              </Button>
            )}
            {loop && (
              <>
                <Button
                  onClick={() => prevSlide()}
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-900"
                >
                  <ChevronLeftIcon />
                </Button>

                <Button
                  onClick={() => nextSlide()}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-900"
                >
                  <ChevronRightIcon />
                </Button>
              </>
            )}
          </>
        )}
      </Tabs>
    )
  },
)

Carousel.displayName = 'Carousel'

export default Carousel
