import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DialogOverlay,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useLocation } from 'wouter'
import { useCreateDocumentFormMutation } from '../hooks'
import { useListProjects } from '@/services/queries/projects'
import { z } from 'zod'
import {
  ControllerRenderProps,
  SubmitHandler,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
} from '@heroicons/react/24/outline'
import { ImageIcon } from '@radix-ui/react-icons'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverPortal,
} from '@/components/ui/popover'
import { Loading } from '@/components/ui/loading'
import { Input } from '@/components/ui/input'
import { cn } from '@/utils'
import { MappedProjectListItem } from '@/lib/api/client'
import { Files } from '@/components/ui/file'
import { SelectCADDropdown } from './SelectCADDropdown'

export const CreateNewDocumentButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={() => setIsOpen(true)}
          className="rounded-full h-8 px-4 py-2 border border-white text-xs text-primary-40 bg-white flex items-center space-x-1 drop-shadow-none"
        >
          <PlusIcon className="h-4 w-4" />
          <span>New Document</span>
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay">
          <DialogContent
            className="max-w-3xl pb-5 overflow-auto min-h-[95%] max-h-[840px] flex flex-col"
            ariaLabel="Create new document dialog"
          >
            <DialogHeader>
              <div className="pb-5 border-b">
                <DialogTitle className="text-center">
                  Create New Document
                </DialogTitle>
              </div>
            </DialogHeader>
            <CreateDocumentForm />
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  )
}

enum TemplateOptions {
  WORK_INSTRUCTIONS = 'work_instructions',
  PROJECT_TRACKER = 'project_tracker',
  VISUAL_BOM = 'visual_bom',
}

type NewDocumentForm = {
  documentName: string
  template: TemplateOptions
  cadId: string
  files: any[]
  projectId: string
  projectName: string
}

const formSchema = z.object({
  documentName: z
    .string({
      required_error: 'Document name is required.',
    })
    .min(2, {
      message: 'Document name must be at least 2 characters.',
    }),
  cadId: z.string().optional(),
  files: z.array(z.any()).optional(),
  projectId: z.string().optional(),
  projectName: z.string().optional(),
  template: z.nativeEnum(TemplateOptions),
})

const CreateDocumentForm = () => {
  const [, setLocation] = useLocation()
  const { mutate: createNewDocument, isPending: isCreatingNewDocument } =
    useCreateDocumentFormMutation({
      onSuccess: ({ projectId, documentId }) => {
        setLocation(`/p/${projectId}/document/${documentId}/cad`)
      },
    })

  const projectsList = useListProjects({ limit: 5, offset: 0 })
  const totalProjects = useMemo(
    () => projectsList.data?.pages[0].totalProjects || 0,
    [projectsList],
  )
  const projects = useMemo(
    () => projectsList.data?.pages.flatMap((page) => page.projects) || [],
    [projectsList],
  )

  const form = useForm<NewDocumentForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      documentName: '',
      template: undefined,
      cadId: '',
      files: [],
      projectId: '',
      projectName: '',
    },
  })

  const documentName = form.watch('documentName')
  const template = form.watch('template')
  const files = form.watch('files')
  const cadId = form.watch('cadId')
  const projectId = form.watch('projectId')
  const projectName = form.watch('projectName')

  const isSubmitButtonEnabled = useMemo(
    () =>
      !isCreatingNewDocument &&
      !!documentName &&
      !!template &&
      (files.length > 0 || !!cadId) &&
      (!!projectId || !!projectName),
    [
      isCreatingNewDocument,
      documentName,
      template,
      files.length,
      cadId,
      projectId,
      projectName,
    ],
  )

  const validate = useCallback(
    (values: z.infer<typeof formSchema>) => {
      let isValid = true

      if (!values.documentName) {
        isValid = false
        form.setError('documentName', {
          type: 'required',
          message: 'Document name is required.',
        })
      }

      if (totalProjects <= 0) {
        if (!values.projectName) {
          isValid = false
          form.setError('projectName', {
            type: 'required',
            message: 'Project name is required.',
          })
        }

        if ((values.files || []).length < 1) {
          isValid = false
          form.setError('files', {
            type: 'required',
            message: 'Upload at least 1 CAD file.',
          })
        }
      }

      return { isValid }
    },
    [totalProjects, form],
  )

  const onSubmit: SubmitHandler<NewDocumentForm> = (values) => {
    try {
      const { isValid } = validate(values)
      if (!isValid) return
      const { documentName, cadId, files, projectId, projectName, template } =
        values
      createNewDocument({
        projectName,
        formValues: {
          documentName,
          cadId,
          files,
          projectId,
          template,
        },
        isCreatingNewProject: !projectId && !!projectName,
        isUploadingNewCAD: files.length > 0 && !cadId,
      })
    } catch (e) {
      throw Error(`Unable to submit form: ${e}`)
    }
  }

  const onChangeFiles = useCallback((files) => {
    form.setValue('files', files)
    form.setValue('cadId', '')
  }, [])

  return (
    <Form {...form}>
      <form
        onSubmit={(event) => void form.handleSubmit(onSubmit)(event)}
        className="flex flex-1 flex-col"
      >
        <div className="mb-4">
          <FormField
            control={form.control}
            name="template"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="template">Document Template</FormLabel>
                <FormControl>
                  <div className="flex space-x-4" id="template">
                    <label
                      className="cursor-pointer flex-1"
                      htmlFor="work-instructions"
                    >
                      <input
                        id="work-instructions"
                        type="radio"
                        value="work_instructions"
                        checked={field.value === 'work_instructions'}
                        onChange={() => field.onChange('work_instructions')}
                        className="hidden"
                      />
                      <div
                        className="px-4 py-2 rounded-lg"
                        data-testid="create-document-work-instructions-option"
                      >
                        <WorkInstructionsTemplate
                          isSelected={field.value === 'work_instructions'}
                        />
                      </div>
                    </label>
                    <label
                      className="cursor-pointer flex-1"
                      htmlFor="project-tracker"
                    >
                      <input
                        id="project-tracker"
                        type="radio"
                        value="project_tracker"
                        checked={field.value === 'project_tracker'}
                        onChange={() => field.onChange('project_tracker')}
                        className="hidden"
                      />
                      <div
                        className="px-4 py-2 rounded-lg"
                        data-testid="create-document-project-tracker-option"
                      >
                        <WorkbookTemplate
                          isSelected={field.value === 'project_tracker'}
                        />
                      </div>
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="documentName"
            render={({ field }) =>
              form.watch('template') ? (
                <FormItem>
                  <FormLabel htmlFor="document-name">Document Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete="off"
                      id="document-name"
                      placeholder="Document name"
                      className="rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="projectId"
            render={({ field }) =>
              form.watch('documentName') ||
              form.watch('projectId') ||
              form.watch('projectName') ? (
                <FormItem>
                  <FormLabel htmlFor="document-name">
                    Project{' '}
                    <span className="text-slate-400">
                      | Assign document to a project
                    </span>
                  </FormLabel>
                  <FormControl>
                    <SearchableSelect
                      projects={projects}
                      field={field}
                      setValue={form.setValue}
                      formWatch={form.watch}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div className="mb-4 flex items-center">
          {form.watch('projectId') ||
          form.watch('projectName') ||
          form.watch('files').length > 0 ||
          form.watch('cadId') ? (
            <>
              <div className="flex-1 space-x-2">
                <FormField
                  control={form.control}
                  name="files"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel htmlFor="cad-id">
                        CAD{' '}
                        <span className="text-slate-400">
                          | Add CAD to document
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Files
                          placeholder={
                            <span className="flex items-center text-slate-500">
                              + Upload new CAD{' '}
                              <span className="block ml-2 py-0.5 px-1 border-2 border-dashed">
                                STEP file
                              </span>
                            </span>
                          }
                          testId="upload-cad"
                          isActive={field.value.length > 0}
                          onChange={onChangeFiles}
                          files={form.watch('files')}
                          validateFile={(file) => {
                            const validExtentions = ['step', 'stp']
                            const tok = file.name.toLowerCase().split('.')
                            const ext = tok[tok.length - 1]

                            const hasValidFileExtention =
                              validExtentions.includes(ext)
                            if (!hasValidFileExtention) {
                              form.setError('files', {
                                type: 'extention',
                                message: `Only files with the following extensions are allowed: ${validExtentions.join(
                                  ', ',
                                )}.`,
                              })
                            }

                            const isValid = hasValidFileExtention

                            return isValid
                          }}
                          error={form.formState.errors.files?.message}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <span className="mx-2 text-slate-400 text-sm mt-8">OR</span>
              <div className="flex-1 space-x-2 mt-8">
                <FormField
                  control={form.control}
                  name="cadId"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectCADDropdown
                          {...field}
                          projectId={form.watch('projectId')}
                          onCadSelect={() => {
                            form.setValue('files', [])
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex justify-end absolute bottom-4 right-4">
          <Button type="submit" disabled={!isSubmitButtonEnabled}>
            {isCreatingNewDocument ? (
              <div className="flex items-center space-x-2">
                <span>Uploading</span>
                <Loading />
              </div>
            ) : (
              'Create Document'
            )}
          </Button>
        </div>
      </form>
    </Form>
  )
}

type SearchableSelectProps = {
  projects: MappedProjectListItem[]
  field: ControllerRenderProps<NewDocumentForm, 'projectId'>
  setValue: UseFormSetValue<NewDocumentForm>
  formWatch: UseFormWatch<NewDocumentForm>
}
const SearchableSelect = ({
  projects,
  field,
  setValue,
  formWatch,
}: SearchableSelectProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase()),
  )

  const onCloseOptions = useCallback((event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node)) {
      setOpen(false)
    }
  }, [])

  const onEscapeOptions = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }, [])

  const onCreateProject = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        setValue('projectName', search)
        setValue('projectId', '')
        setOpen(false)
      }
    },
    [search],
  )

  const ChevronIcon = useMemo(
    () => (open ? ChevronUpIcon : ChevronDownIcon),
    [open],
  )

  const showNote = useMemo(
    () => !!search && isFocused && open,
    [search, isFocused, open],
  )

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100)
      document.addEventListener('click', onCloseOptions)
      document.addEventListener('keydown', onEscapeOptions)
    } else {
      document.removeEventListener('click', onCloseOptions)
      document.removeEventListener('keydown', onEscapeOptions)
    }

    return () => {
      document.removeEventListener('click', onCloseOptions)
      document.removeEventListener('keydown', onEscapeOptions)
    }
  }, [open, onCloseOptions, onEscapeOptions])

  useEffect(() => {
    if (showNote) {
      document.addEventListener('keydown', onCreateProject)
    } else {
      document.removeEventListener('keydown', onCreateProject)
    }

    return () => {
      document.removeEventListener('keydown', onCreateProject)
    }
  }, [showNote, onCreateProject])

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <div ref={containerRef} className="relative w-full flex items-center">
          {!!formWatch('projectName') && (
            <span className="absolute left-2 text-xs py-0.5 px-1.5 bg-blue-100 text-blue-500 rounded">
              NEW
            </span>
          )}
          <Input
            {...field}
            ref={inputRef}
            type="text"
            placeholder="Type to find existing project or create a new one"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => {
              setOpen(true)
              setIsFocused(true)
            }}
            onBlur={() => setIsFocused(false)}
            className={cn('w-full p-2 border rounded-lg focus:outline-none', {
              'pr-[12rem]': showNote,
              'pl-[3.5rem]': !!formWatch('projectName'),
            })}
          />
          <ChevronIcon className="absolute right-2 top-2.5 w-4 h-4 text-gray-500 pointer-events-none" />
          {showNote && (
            <span className="absolute right-8 top-2.5 text-xs text-blue-500">
              Hit [enter] to create new
            </span>
          )}
        </div>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent
          className="bg-white z-[1000] border rounded-lg shadow-lg"
          sideOffset={4}
          align="start"
          style={{
            width: containerRef.current?.offsetWidth,
          }}
          onPointerDownCapture={(e) => e.preventDefault()}
        >
          <div className="max-h-40 overflow-y-auto">
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project, i) => (
                <div
                  data-testid={`project-option--${i}`}
                  key={project.id}
                  className={`flex items-center justify-between p-2 rounded-md cursor-pointer hover:bg-gray-100 ${
                    formWatch('projectId') === project.id ? 'bg-gray-200' : ''
                  }`}
                  onClick={() => {
                    setValue('projectId', project.id as string)
                    setValue('projectName', '')
                    setSearch(project.name)
                    setOpen(false)
                    inputRef.current?.blur()
                  }}
                >
                  {project.name}
                  {formWatch('projectId') === project.id && (
                    <CheckIcon className="w-4 h-4" />
                  )}
                </div>
              ))
            ) : (
              <p className="p-1 text-gray-500">No results found</p>
            )}
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}

type TemplateProps = {
  isSelected: boolean
}
const WorkInstructionsTemplate = ({ isSelected }: TemplateProps) => {
  return (
    <div className="flex items-center space-x-2 flex-col">
      <span className="font-semibold text-xs mb-2">Work Instructions</span>
      <div
        className={cn(
          'border w-[354px] h-[256px] w-full p-3 flex flex-col rounded-md',
          {
            'border-blue-500': isSelected,
          },
        )}
      >
        <div className="flex items-center space-x-2">
          <div className="flex-1 flex flex-col border-collapse">
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px] border-b-0', {
                  'border-blue-500': isSelected,
                  'bg-blue-200': isSelected,
                  'bg-gray-100': !isSelected,
                })}
              />
              <div
                className={cn('flex-1 border h-[13px] border-l-0 border-b-0', {
                  'border-blue-500': isSelected,
                  'bg-blue-200': isSelected,
                  'bg-gray-100': !isSelected,
                })}
              />
            </div>
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px] border-b-0', {
                  'border-blue-500': isSelected,
                })}
              />
              <div
                className={cn('flex-1 border h-[13px] border-l-0 border-b-0', {
                  'border-blue-500': isSelected,
                })}
              />
            </div>
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px]', {
                  'border-blue-500': isSelected,
                })}
              />
              <div
                className={cn('flex-1 border h-[13px] border-l-0', {
                  'border-blue-500': isSelected,
                })}
              />
            </div>
          </div>

          <div className="flex-[0.4] flex flex-col border-collapse">
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px] border-b-0', {
                  'border-blue-500': isSelected,
                  'bg-blue-200': isSelected,
                  'bg-gray-100': !isSelected,
                })}
              />
            </div>
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px] border-b-0', {
                  'border-blue-500': isSelected,
                })}
              />
            </div>
            <div className="flex flex-1">
              <div
                className={cn('flex-1 border h-[13px]', {
                  'border-blue-500': isSelected,
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-1 space-x-2 items-center">
          <div
            className={cn('flex-1 flex items-center justify-center h-full', {
              'bg-blue-100': isSelected,
              'bg-gray-100': !isSelected,
            })}
          >
            <ImageIcon
              className={cn('h4 w-4', {
                'text-blue-500': isSelected,
                'text-gray-500': !isSelected,
              })}
            />
          </div>
          <div className="flex-[0.4] flex flex-col space-y-2">
            {Array.from({ length: 8 }).map((_, index) => (
              <div
                key={index}
                className={cn('h-[9px] w-full rounded', {
                  'bg-blue-100': isSelected,
                  'bg-gray-100': !isSelected,
                })}
              />
            ))}
            <div
              className={cn('h-[9px] w-2/3 rounded', {
                'bg-blue-100': isSelected,
                'bg-gray-100': !isSelected,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const WorkbookTemplate = ({ isSelected }: TemplateProps) => {
  return (
    <div className="flex items-center space-x-2 flex-col">
      <span className="font-semibold text-xs mb-2">Project Workbook</span>
      <div
        className={cn(
          'border w-[354px] h-[256px] w-full p-3 flex flex-col rounded-md',
          {
            'border-blue-500': isSelected,
          },
        )}
      >
        <div className="flex flex-1 space-x-2 items-center">
          <div className="flex-[0.5] flex flex-col space-y-2">
            <div
              className={cn('h-[9px] w-2/3 rounded', {
                'bg-blue-100': isSelected,
                'bg-gray-100': !isSelected,
              })}
            />
            {Array.from({ length: 8 }).map((_, index) => (
              <div
                key={index}
                className={cn('h-[9px] w-full rounded', {
                  'bg-blue-100': isSelected,
                  'bg-gray-100': !isSelected,
                })}
              />
            ))}
          </div>
          <div
            className={cn(
              'flex-[0.5] flex items-center justify-center h-full h-3/5 mt-4',
              {
                'bg-blue-100': isSelected,
                'bg-gray-100': !isSelected,
              },
            )}
          >
            <ImageIcon
              className={cn('h4 w-4', {
                'text-blue-500': isSelected,
                'text-gray-500': !isSelected,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
