import { Squares2X2Icon } from '@heroicons/react/24/outline'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { useCreateDocumentTemplate } from '@/services/queries/document_templates'

interface CreateDocumentTemplateButtonProps {
  documentPageId?: string
  documentVersionId?: string
  isDisabled?: boolean
}

export const CreateDocumentTemplateButton = ({
  documentPageId,
  documentVersionId,
  isDisabled,
}: CreateDocumentTemplateButtonProps) => {
  const { mutate: createDocumentTemplate } = useCreateDocumentTemplate()

  const isButtonDisabled = isDisabled || !documentPageId || !documentVersionId

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="rounded-md hover:bg-gray-100 m-0 h-full flex p-1 items-center justify-center min-w-[50px]"
            onClick={() => {
              if (documentPageId && documentVersionId) {
                createDocumentTemplate({
                  documentPageId,
                  documentVersionId,
                })
              }
            }}
            disabled={isButtonDisabled}
            data-testid="create-document-template-button"
          >
            <span className="flex items-center flex-col space-y-0.5 h-full justify-between">
              <Squares2X2Icon className="w-6 h-6 stroke-2" />
              <span className="text-xs">Templates</span>
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Create Document Template</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
