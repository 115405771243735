import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Html } from '@react-three/drei'
import { useEffect, useState } from 'react'

const SHOW_CAD_TIP_KEY = 'showCADTip'

export const CADEditorTip = () => {
  const [showCADTip, setShowCADTip] = useState(true)

  useEffect(() => {
    if (localStorage.getItem(SHOW_CAD_TIP_KEY) === 'false') setShowCADTip(false)

    const timer = setTimeout(() => {
      localStorage.setItem(SHOW_CAD_TIP_KEY, 'false')
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  }, [showCADTip])

  if (!showCADTip) return null

  return (
    <Html wrapperClass="transform-none w-80 bg-transparent !z-20 !top-5 !left-0 !left-auto select-none">
      <div className="cad-editor-callout">
        <Alert variant="default" className="w-fit">
          <div className="flex items-center space-x-2">
            <AlertTitle className="mb-0">💡</AlertTitle>
            <AlertDescription>
              Tip: Use your right mouse button to pan in CAD.
            </AlertDescription>
          </div>
        </Alert>
      </div>
    </Html>
  )
}
