import { QuestionMarkIcon } from '@radix-ui/react-icons'

const HELP_LINK =
  'https://q20help.notion.site/Our-Comprehensive-Help-Center-16f7d5cb96da80b1acaafaa1b5b63729'

export const HelpButton = () => {
  return (
    <a
      className="bg-white text-black p-2 rounded-full"
      target="_blank"
      rel="noopener noreferrer"
      href={HELP_LINK}
    >
      <QuestionMarkIcon className="w-3.5 h-3.5 font-extrabold" />
    </a>
  )
}
