import {
  Object3DEventMap,
  Object3D,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  Vector3Tuple,
  Matrix4,
  Box3,
  LineBasicMaterial,
  Line,
  BufferGeometry,
  LineSegments,
  Plane,
  Cache,
  Camera,
  LineDashedMaterial,
} from 'three'
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from 'three-mesh-bvh'
import { LRUCache } from 'lru-cache'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {
  mergePartMeshes,
  buildPartWireframe,
  isLeafNode,
  isLeaf,
} from './GLTFMerge'
import { TransformControls } from 'three-stdlib'
import { ASMTreeNode, RawAssemblyTree } from '@/state'
import type { Explosions } from '@/state/cad'
import type { Step } from '@/services/queries/operation_steps/types'
import { wireframeMaterial } from './materials'

import { convertCrossSectionMap } from '@/utils/cad'
import { HIGHLIGHT_BLUE } from '@/constants'
import { validate as isUUID } from 'uuid'

TransformControls.prototype.getRaycaster = function (this: TransformControls) {
  return this['raycaster']
}

TransformControls.prototype.getHelper = function (this: TransformControls) {
  return this['gizmo']['picker']['translate']
}

TransformControls.prototype.getOffset = function (this: TransformControls) {
  return this['offset']
}

Object.defineProperty(TransformControls.prototype, 'isDragging', {
  get: function (this: TransformControls) {
    return this['dragging'] ? true : false
  },
})

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
Mesh.prototype.raycast = acceleratedRaycast
Cache.enabled = true

export type GroupObject =
  | Group
  | Object3D
  | Group<Object3DEventMap>
  | Object3D<Object3DEventMap>

type OriginalPartColors = {
  [key: string]: {
    color: number
    mesh: Mesh
  }
}

type ObjectExplosionsUserData = {
  originalParentName: string
  originalParentWorldPosition: Vector3Tuple
  originalPosition: Vector3Tuple
  originalWorldPosition: Vector3Tuple
}

const ExplosionsUserDataKeys = [
  'originalParentName',
  'originalParentWorldPosition',
  'originalPosition',
  'originalWorldPosition',
]

export class GLTFObject {
  rawGltf: GLTF
  scene: Group<Object3DEventMap>
  originalPartColors: LRUCache<string, OriginalPartColors>
  transformControls: TransformControls | null
  transformGroup: Group<Object3DEventMap> | null
  lines: Line[]
  lineMaterial: LineBasicMaterial
  partObjectMap: Map<string, Object3D>
  objectPartMap: WeakMap<Object3D, ASMTreeNode>

  private initialized: boolean
  private isMerged: boolean

  mergePartMeshes: (obj: Object3D) => number
  buildPartWireframe: (obj: Object3D) => Group
  isLeafNode: (obj: Object3D) => boolean
  isLeaf: (node: ASMTreeNode) => boolean

  constructor(rawGltf: GLTF) {
    this.rawGltf = rawGltf
    this.scene = this.rawGltf.scene
    this.originalPartColors = new LRUCache<string, OriginalPartColors>({
      max: 1000000,
    })
    this.transformGroup = null
    this.transformControls = null
    this.lines = []
    this.lineMaterial = new LineBasicMaterial(wireframeMaterial)
    this.partObjectMap = new Map()
    this.objectPartMap = new Map()

    this.initialized = false
    this.isMerged = false

    this.mergePartMeshes = mergePartMeshes.bind(this)
    this.buildPartWireframe = buildPartWireframe.bind(this)
    this.isLeafNode = isLeafNode.bind(this)
    this.isLeaf = isLeaf.bind(this)
  }

  setup(
    assemblyTree: RawAssemblyTree,
    options: {
      mergeModelParts?: boolean
      computeBoundsTree?: boolean
      layerMap: { [x: string]: number }
      camera?: Camera
    },
  ) {
    if (this.initialized) return
    const mergeModelParts = options.mergeModelParts ?? true
    const computeBoundsTree = options.computeBoundsTree ?? false

    // Merge Model Parts
    if (mergeModelParts) this.mergeModelParts()

    // Generate Object Maps
    this.generateObjectMaps(assemblyTree)

    this.scene.traverse((object) => {
      // Compute Bounds Tree
      if (object instanceof Mesh) {
        if (computeBoundsTree) object.geometry.computeBoundsTree()
      }

      // Set Layers
      const layer = options.layerMap[object.constructor.name]
      if (layer) object.layers.set(layer)
    })

    this.initialized = true
  }

  cleanupModel(opts: { computeBoundsTree: boolean }) {
    this.scene.traverse((object) => {
      if (object instanceof Mesh) {
        if (opts.computeBoundsTree) object.geometry.disposeBoundsTree()
      }
    })
  }

  mergeModelParts() {
    let nMeshes = 0
    if (this.isMerged) return nMeshes

    const queue = [this.scene]

    while (queue.length > 0) {
      const obj = queue.pop() as Object3D<Object3DEventMap>
      if (!obj) continue
      if (this.isLeafNode(obj)) {
        const wireframeNode = this.buildPartWireframe(obj)
        nMeshes += this.mergePartMeshes(obj)
        obj.add(wireframeNode)
      } else {
        queue.push(...(obj.children as any))
      }
    }

    this.isMerged = true
    return nMeshes
  }

  generateObjectMaps(assemblyTree: RawAssemblyTree) {
    assemblyTree.nodes.forEach((node) => {
      if (node.uuid === assemblyTree.root) return
      const object = this.getObjectByName(node.instance)
      if (object) {
        this.partObjectMap.set(node.uuid, object)
        this.objectPartMap.set(object, node)
      }
    })
  }

  formatPartName(partName: string) {
    return partName.startsWith('Group:')
      ? partName
      : partName.replace(' ', '_').replace(':', '')
  }

  viewPart(assemblyTree: RawAssemblyTree, target: string) {
    const root = assemblyTree.nodes.find(
      (node) => node.uuid === assemblyTree.root,
    )
    const part = assemblyTree.nodes.find((node) => node.instance === target)
    const object = this.scene.getObjectByName(part?.instance as string)

    if (!object || !part || !root) return

    this.scene.getObjectByName(root.instance)?.traverse((child) => {
      child.layers.disableAll()
    })

    object.traverse((child) => {
      child.layers.enableAll()
    })
  }

  setVisibility(partId: string, isVisible: boolean) {
    const part = this.getObjectByName(this.formatPartName(partId))
    if (part) part.visible = isVisible
  }

  setVisibilityForOperationSteps({
    assemblyTree,
    steps,
    hiddenParts,
    activeStep,
    explosions,
    isExplosionLinesEnabled,
  }: {
    assemblyTree: RawAssemblyTree
    steps: Step[]
    hiddenParts: string[]
    activeStep?: Step | null
    explosions: Explosions
    isExplosionLinesEnabled: boolean
  }) {
    const stepNumber = activeStep?.order_number
    const isActive = Boolean(activeStep)

    const assemblyGroupIdsUpToStep = new Set(
      steps.slice(0, stepNumber).flatMap((s) => {
        return this.getVisiblePathOfGroupIds({
          assemblyGroupIds: s.assembly_group_ids,
          assemblyTree,
        })
      }),
    )

    const rootNode = assemblyTree.nodes.find(
      (node) => node.uuid === assemblyTree.root,
    )

    const visibleExplosions = {}

    // Perform a depth first traversal down the assembly-tree
    // and set the vibility for each node in the tree
    const dfsNodeQueue = [...(rootNode?.children ?? [])]
    const visitedNodes = new Set()

    while (dfsNodeQueue.length > 0) {
      const nodeUUID = dfsNodeQueue.pop()
      while (!nodeUUID) continue

      if (visitedNodes.has(nodeUUID)) continue
      visitedNodes.add(nodeUUID)

      const node = assemblyTree.nodes.find((n) => n.uuid === nodeUUID)
      if (!node) continue
      const isHiddenPart = hiddenParts.includes(node.uuid)

      const isVisible = isActive
        ? assemblyGroupIdsUpToStep.has(node.uuid) && !isHiddenPart
        : !isHiddenPart

      if (isVisible && node.instance in explosions) {
        visibleExplosions[node.instance] = explosions[node.instance]
      }

      this.setVisibility(node.instance, isVisible)

      node.children.forEach((childUUID) => dfsNodeQueue.push(childUUID))
    }

    this.showExplosionLines(
      visibleExplosions,
      assemblyTree,
      isExplosionLinesEnabled,
      { hiddenParts },
    )
  }

  getVisiblePathOfGroupIds({
    assemblyGroupIds,
    assemblyTree,
  }: {
    assemblyGroupIds: string[]
    assemblyTree: RawAssemblyTree
  }) {
    /**
     *
     *  Include all of the parent assembly-group-ids, this will
     *  prevent unintentionally hiding parents because their parents
     *  were hidden.
     *
     *  You can think of this as the "visible path" taken to make a
     *  part in the assembly tree visible
     *
     *  For example, Let's say we have the following assembly tree
     *
     *    - prop-asm
     *        - BrushlessMotor
     *        - Blade
     *
     *  If `prop-asm` is added to step than we'll want to set visibility
     *  to true for `prop-asm`, `BrushlessMotor` and `Blade`.
     *
     *  If only `BrushlessMotor` is added to a step than we'll want to set the
     *  vibility to true for `prop-asm` and `BrushlessMotor`
     */

    const groupIds = [...assemblyGroupIds]

    assemblyGroupIds.forEach((groupId) => {
      const node = assemblyTree.nodes.find((n) => n.uuid === groupId)

      // Traverse up the parent nodes. We need the parent nodes to be
      // visible
      const nodeParentQueue =
        node?.parent && node.parent !== assemblyTree.root ? [node.parent] : []

      while (nodeParentQueue.length > 0) {
        const parent = nodeParentQueue.pop()
        if (!parent) continue

        const parentNode = assemblyTree.nodes.find((n) => n.uuid === parent)
        if (!parentNode) continue

        groupIds.push(parentNode.uuid)

        if (parentNode?.parent && parentNode.parent !== assemblyTree.root) {
          nodeParentQueue.push(parentNode.parent)
        }
      }

      // If a node has children than we'll want to make all of the
      // child nodes visible
      const nodeChildrenQueue = [...(node?.children ?? [])]

      while (nodeChildrenQueue.length > 0) {
        const child = nodeChildrenQueue.pop()
        if (!child) continue

        const childNode = assemblyTree.nodes.find((n) => n.uuid === child)
        if (!childNode) continue

        groupIds.push(childNode.uuid)

        childNode.children.forEach((subChild) =>
          nodeChildrenQueue.push(subChild),
        )
      }
    })

    return groupIds
  }

  getObjectByUUID(uuid: string): Object3D | undefined {
    const obj = this.partObjectMap.get(uuid)
    return obj
  }

  getObjectByName(name: string): Group | Object3D | undefined {
    if (isUUID(name)) {
      const obj = this.getObjectByUUID(name)
      if (obj) return obj
    }
    return this.scene.getObjectByName(name)
  }

  /**
   * highlightPart - Highlights a part in the assembly tree.
   * @param partName
   */
  highlightPart(partId: string, color?: number) {
    const highlightColor = color || HIGHLIGHT_BLUE
    this.setColor(partId, highlightColor)
  }

  /**
   * unhighlightParts - Unhighlights a part in the assembly tree.
   */
  unhighlightParts(colorMap: { [key: string]: number }) {
    this.resetColors({ colorMap })
  }

  /**
   * setColor - Sets or resets the color of a part in the CAD model
   * @param partName
   * @returns the new color of the part, or the previous color if the part was already that color
   */
  setColor(partId: string, color: number | string) {
    const formattedPartName = this.formatPartName(partId)
    const object = this.getObjectByName(formattedPartName)
    const cachedColors = this.originalPartColors.get(formattedPartName) || {}

    object?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()

        if (!(mesh.name in cachedColors)) {
          const oldColor = matClone.color.getHex()
          cachedColors[mesh.name] = {
            color: oldColor,
            mesh,
          }
        }

        const parsedColor =
          typeof color === 'number' ? color : parseInt(color.slice(1), 16)

        matClone.color.setHex(parsedColor)
        mesh.material = matClone
      }
    })
    this.originalPartColors.set(formattedPartName, cachedColors)
  }

  resetColors(options?: { colorMap?: { [key: string]: number } }) {
    const colorMap = options?.colorMap || {}
    const entries = this.originalPartColors.entries()

    if (!entries) return

    for (const [partName, colors] of entries) {
      const partColor = partName in colorMap ? colorMap[partName] : null

      Object.entries(colors).forEach(([, colorData]) => {
        const color = partColor || colorData.color
        const mesh = colorData.mesh
        if (mesh) {
          const material = mesh.material as MeshBasicMaterial
          material.color.setHex(color)
        }
      })

      if (!(partName in colorMap)) {
        this.originalPartColors.delete(partName)
      }
    }
  }

  resetColor(partName: string) {
    if (!this.originalPartColors.has(partName)) return

    const formattedPartName = this.formatPartName(partName)
    const object = this.getObjectByName(formattedPartName)
    const cachedColors = this.originalPartColors.get(formattedPartName) || {}

    object?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()

        const color = cachedColors[mesh.name].color

        matClone.color.setHex(color)
        mesh.material = matClone
      }
    })
    this.originalPartColors.delete(formattedPartName)
  }

  resetHiddenParts(hiddenParts: string[], assemblyTree: RawAssemblyTree) {
    hiddenParts.forEach((partUUID) => {
      const part = assemblyTree.nodes.find((n) => n.uuid === partUUID)
      if (part) {
        this.setVisibility(part.instance, true)
      }
    })
  }

  /**
   * setTransparency - Sets the transparency of a part in the CAD model
   * @param partName
   */

  setTransparency(partName: string, transparency: number = 0.3) {
    const formattedPartName = this.formatPartName(partName)
    const object = this.getObjectByName(formattedPartName)
    let isTransparent = false

    object?.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh
        const material = mesh.material as MeshBasicMaterial
        const matClone = material.clone()
        if (matClone.transparent) {
          matClone.opacity = 1
          matClone.transparent = false
        } else {
          matClone.transparent = true
          matClone.opacity = transparency
          isTransparent = true
        }
        mesh.material = matClone
      }
    })

    return isTransparent
  }

  resetTransparency(transparentParts: string[]) {
    transparentParts.forEach((instanceName) => {
      const formattedPartName = this.formatPartName(instanceName) as string
      const node = this.getObjectByName(formattedPartName as string)
      if (!node) return

      node.traverse((child) => {
        if (child.type === 'Mesh') {
          const mesh = child as Mesh
          const material = mesh.material as MeshBasicMaterial
          const matClone = material.clone()
          if (matClone.transparent) {
            matClone.opacity = 1
            matClone.transparent = false
            mesh.material = matClone
          }
        }
      })
    })
  }

  resetExplosions(explosions: Explosions) {
    this.resetTransformGroup()
    this.explodeParts(explosions, { reset: true })
  }

  explodeParts(explosions: Explosions, options?: { reset?: boolean }) {
    Object.entries(explosions).forEach(([partId, explosion]) => {
      const object = this.getObjectByName(this.formatPartName(partId))

      if (!object) return

      if (options?.reset) {
        this.resetObjectState(object)
      } else {
        this.saveObjectState(object)
      }

      const position = options?.reset
        ? explosion.originalPosition
        : explosion.position

      object.matrixAutoUpdate = false
      const newPosition = new Vector3().fromArray(position)
      const tempMatrix = new Matrix4().copy(object.matrix)
      tempMatrix.setPosition(newPosition)
      object.position.copy(newPosition)
      object.matrix.copy(tempMatrix)
    })
  }

  /**
   * Groups parts based on assembly tree state and adds them to the scene.
   *
   * @param tree - The raw assembly tree.
   */
  groupParts(tree: RawAssemblyTree) {
    const nodes = tree?.nodes ?? []

    const getNewGroups = (scene: Object3D) =>
      nodes
        .map((node) => {
          if (!node.group) {
            return null
          }
          const newGroup = new Group()
          newGroup.name = node.instance

          node.children.forEach((childUUID) => {
            const childNode = nodes.find((n) => n.uuid === childUUID)
            if (childNode) {
              const child = scene.getObjectByName(childNode.instance)
              if (child) {
                newGroup.add(child)
              }
            }
          })

          return newGroup
        })
        .filter(Boolean) as Group[]

    const newGroups = getNewGroups(this.scene)

    newGroups.forEach((groupObj) => {
      const oldGroup = this.getObjectByName(groupObj.name)
      if (oldGroup) {
        this.scene?.remove(oldGroup)
      }
      this?.scene?.add(groupObj)
    })
  }

  initTransformControls(controls: TransformControls) {
    if (!this.transformControls) {
      // #TODO: fix offset problem
      this.scene.attach(controls)
      this.transformControls = controls
      return this.transformControls
    }
  }

  cleanupTransformControls() {
    if (this.transformControls) {
      this.transformControls.dispose()
      this.transformControls = null
    }
  }

  initTransformGroup(onAdded: () => void) {
    if (!this.transformGroup) {
      const transformGroup = new Group()
      transformGroup.name = 'explosions-transform-group'
      transformGroup.userData.isMerged = true
      this.transformGroup = transformGroup
    }
    this.transformGroup.addEventListener('added', onAdded)
    this.scene.add(this.transformGroup)
    return this.transformGroup
  }

  resetTransformGroup() {
    if (this.transformGroup) {
      // Return all children to their original parents before cleanup
      while (this.transformGroup.children.length) {
        const child = this.transformGroup.children[0]
        this.returnToParent(child)
      }
    }
  }

  cleanupTransformGroup(onAdded: () => void) {
    if (this.transformGroup) {
      this.transformGroup.removeEventListener('added', onAdded)
      this.resetTransformGroup()
      this.scene.remove(this.transformGroup)
      this.transformGroup = null
    }
  }

  getOriginalParent(obj?: Object3D) {
    const { originalParentName } = obj?.userData as ObjectExplosionsUserData
    return this.getObjectByName(originalParentName)
  }

  returnToParent(obj: Object3D) {
    const parent = this.getOriginalParent(obj)

    if (parent) parent.attach(obj)
    else console.error('No parent found for object', obj)
  }

  saveObjectState(obj: Object3D) {
    const parent = obj.parent
    const userData = obj.userData as ObjectExplosionsUserData

    if (parent && !userData.originalParentName) {
      userData.originalParentName = parent.name
      userData.originalParentWorldPosition = parent
        .getWorldPosition(new Vector3())
        .toArray() as Vector3Tuple
    }

    if (!userData.originalPosition) {
      userData.originalPosition = obj.position.toArray() as Vector3Tuple
    }

    if (!userData.originalWorldPosition) {
      userData.originalWorldPosition = obj
        .getWorldPosition(new Vector3())
        .toArray() as Vector3Tuple
    }
  }

  resetObjectState(obj: Object3D) {
    const userData = obj.userData as ObjectExplosionsUserData
    const parentName = userData.originalParentName
    const parent = this.getObjectByName(parentName)

    if (parent) {
      parent.attach(obj)
      obj.userData = Object.entries(userData).filter(
        ([key]) => !ExplosionsUserDataKeys.includes(key),
      )
    }
  }

  drawDragLine(instanceName: string, dir: 'x' | 'y' | 'z') {
    const targetMesh = this.getObjectByName(instanceName)
    if (!targetMesh) return

    const material = new LineBasicMaterial({
      color: 0xf3f4f6, // Blue color for the line
    })

    const boundingBox = new Box3().setFromObject(targetMesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    const size = new Vector3()
    boundingBox.getSize(size)

    // Extend the start and end points further along the X-axis

    // Adjust these points based on the targetMesh's bounding box and your desired direction
    const points: Vector3[] = []

    if (dir === 'x') {
      const lineLength = size.x * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x - lineLength / 2, center.y, center.z)) // Start point extended
      points.push(new Vector3(center.x + lineLength / 2, center.y, center.z)) // End point extended
    } else if (dir === 'y') {
      const lineLength = size.y * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x, center.y - lineLength / 2, center.z)) // Start point of the line along the Y-axis
      points.push(new Vector3(center.x, center.y + lineLength / 2, center.z)) // End point of the line along the Y-axis
    } else if (dir === 'z') {
      const lineLength = size.z * 10 // Making the line longer based on the largest dimension
      points.push(new Vector3(center.x, center.y, center.z - lineLength / 2)) // Start point of the line along the Z-axis
      points.push(new Vector3(center.x, center.y, center.z + lineLength / 2)) // End point of the line along the Z-axis
    }

    const geometry = new BufferGeometry().setFromPoints(points)

    const line = new Line(geometry, material)

    // Optionally, adjust the line's position to match the target mesh's position
    line.position.copy(center)

    this.clearDragLines()
    this.scene.add(line)
    this.lines.push(line)
  }

  clearDragLines() {
    this.lines.forEach((line) => {
      this.scene.remove(line)
    })
    this.lines = []
  }

  showExplosionLines(
    explosions: Explosions,
    assemblyTree: RawAssemblyTree,
    isEnabled: boolean,
    options?: { hiddenParts?: string[] },
  ) {
    const groupName = 'explosion-lines'
    const oldGroup = this.scene.getObjectByName(groupName)
    if (oldGroup) {
      this.scene.remove(oldGroup)
    }

    if (!isEnabled) {
      return
    }

    const group = new Group()
    group.name = groupName
    group.userData.isMerged = true
    this.scene.add(group)

    const hiddenPartNames = (options?.hiddenParts || [])
      .map((partUUID) => {
        const part = assemblyTree.nodes.find((n) => n.uuid === partUUID)
        return part?.instance
      })
      .filter((partName) => !!partName)

    Object.entries(explosions).forEach(([key, explosion]) => {
      if (
        (options?.hiddenParts && options?.hiddenParts.includes(key)) ||
        hiddenPartNames.includes(key)
      ) {
        console.log('Skipping hidden part', name)
        return
      }

      const object = isUUID(key)
        ? this.getObjectByUUID(key)
        : this.getObjectByName(this.formatPartName(key) as string)

      const tempParent = this.getObjectByName(object?.parent?.name as string)
      const parent =
        tempParent === this.transformGroup
          ? this.getOriginalParent(object)
          : tempParent

      if (!object || !parent) return

      const startPoint = parent.localToWorld(
        new Vector3(
          explosion.originalPosition[0],
          explosion.originalPosition[1],
          explosion.originalPosition[2],
        ),
      )
      const endPoint = parent.localToWorld(
        new Vector3(
          explosion.position[0],
          explosion.position[1],
          explosion.position[2],
        ),
      )

      if (startPoint.equals(endPoint)) {
        return
      }

      const geometry = new BufferGeometry().setFromPoints([
        startPoint,
        endPoint,
      ])

      const material = new LineDashedMaterial({
        color: 0xff0000,
        linewidth: 2 / 500,
        dashSize: 2 / 500,
        gapSize: 1 / 500,
      })

      const line = new Line(geometry, material)
      line.name = `explodeLine_${name}`
      line.userData.isMerged = true
      line.computeLineDistances()
      group.attach(line)
    })
  }

  isClippable(object: Object3D) {
    return (
      object instanceof Mesh ||
      object instanceof LineSegments ||
      object instanceof Line
    )
  }

  setPartClippingPlanes(
    clippingPlanes: Plane[],
    partName: string,
    planeIds: number[],
  ) {
    const part = this.getObjectByName(partName)
    if (part) {
      part.traverse((obj) => {
        if (this.isClippable(obj)) {
          obj.onBeforeRender = () => {
            obj.material.clippingPlanes = planeIds.map((i) => clippingPlanes[i])
          }
        }
      })
    }
  }

  resetClippingPlanes() {
    this.scene.traverse((obj) => {
      if (this.isClippable(obj)) {
        obj.onBeforeRender = () => {
          obj.material.clippingPlanes = []
        }
      }
    })
  }

  /**
   * Sets the clipping planes for meshes in the scene. If a cross-section map is provided,
   * it will set the clipping planes for specific parts of the object based on the map.
   * Otherwise, it will apply the clipping planes to all meshes in the scene.
   */
  setClippingPlanes(clippingPlanes: Plane[], crossSectionMap?: string[][]) {
    if (!clippingPlanes) return

    this.resetClippingPlanes()
    if (clippingPlanes.length === 0) return

    // Correct clipping plane types
    clippingPlanes.forEach(
      (p, i) => (clippingPlanes[i] = new Plane(p.normal, p.constant)),
    )

    if (crossSectionMap) {
      const sectionObjectMap = convertCrossSectionMap(crossSectionMap)
      Object.entries(sectionObjectMap).forEach(([partName, planeIds]) => {
        this.setPartClippingPlanes(clippingPlanes, partName, planeIds)
      })
    } else {
      this.scene.traverse((obj) => {
        if (this.isClippable(obj)) {
          obj.onBeforeRender = () => {
            obj.material.clippingPlanes = clippingPlanes
          }
        }
      })
    }
  }

  getObjectMouseCoords(
    name: string,
    camera: Camera,
    domElement: HTMLCanvasElement,
  ) {
    const obj = this.getObjectByName(name)
    if (!obj) return null

    const vec = obj.position.clone().project(camera)

    // Use clientWidth and clientHeight for accurate dimensions
    const canvasRect = domElement.getBoundingClientRect()

    const x = ((vec.x + 1) / 2) * canvasRect.width + canvasRect.left
    const y = ((-vec.y + 1) / 2) * canvasRect.height + canvasRect.top

    return { x, y }
  }
}
