import { LockClosedIcon } from '@heroicons/react/24/solid'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'

export const ReadOnlyBanner = () => {
  const {
    isLoading,
    data: {
      isReadOnly,
      isLatestCadVersion,
      isLatestDocumentVersion,
      isUserReadOnly,
      ...readOnlyData
    },
  } = useIsReadOnly()

  if (isLoading || !isReadOnly) {
    return null
  }

  const cadVersion = isLatestCadVersion
    ? 'Latest'
    : `V${readOnlyData.cadVersion}`

  const docVersion = isLatestDocumentVersion
    ? 'Latest'
    : readOnlyData.docVersion

  const content = !(isLatestCadVersion && isLatestDocumentVersion) ? (
    <span>
      You are viewing a previous version of this document.{' '}
      <span className="font-semibold text-xs">
        [CAD: {cadVersion}] [Document: {docVersion}]
      </span>
    </span>
  ) : isUserReadOnly ? (
    <span>
      As a read-only user, you cannot make any changes to this document.
    </span>
  ) : null

  return (
    <Alert variant="warning">
      <div className="flex items-center space-x-2">
        <LockClosedIcon className="h-4 w-4 stroke-amber-700 fill-amber-700" />
        <AlertTitle className="mb-0">Read Only:</AlertTitle>
        <AlertDescription>{content}</AlertDescription>
      </div>
    </Alert>
  )
}
