import { ThreeEvent } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'

import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore, WAND } from '@/state/cad'
import {
  Selectable,
  SelectionEvent,
  useSelectable,
} from '@/pages/CADPage/hooks'
import { GLTFObject } from '@/lib/cad/GLTFObject'

import { CADPageState } from '@/state/cad'
import { useAddPartsFromCAD } from '../../AssemblyOrderPanel/StepCardList/hooks/useAddPartsFromCAD'

/**
 * Custom hook that handles interactions with the CAD model.
 *
 * @returns {void | ModelHandlers}
 * Returns the model handlers if the data is loaded, otherwise returns nothing.
 */
export const useModelHandlers = () => {
  const { isLoading, data } = useCADQuery()
  const { onAddPartFromCAD } = useAddPartsFromCAD()
  const gltf = data?.gltf

  const selectable = useSelectable({
    onSelect: onAddPartFromCAD,
    onMultiSelect: onAddPartFromCAD,
  })

  const state = useCADPageStore(useShallow((state) => state))

  if (isLoading || !gltf) return

  return createModelHandlers(selectable, gltf, state)
}

/**
 * Creates handlers for interacting with the CAD model.
 *
 * @param gltf
 * @param assemblyTree
 * @param state
 * @param selectable - Object responsible for handling selection.
 * @returns Handlers for pointer enter, pointer down, and pointer up/leave events.
 */
export const createModelHandlers = (
  selectable: Selectable,
  gltf: GLTFObject,
  state: CADPageState,
) => {
  /*
   * Handler for when the pointer enters a part in the CAD model.
   */
  const handlePointerEnter = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()
    if (state.isRotating || state.isDragging) return
    if (gltf.transformControls) {
      const helper = gltf.transformControls.getHelper()
      const raycaster = gltf.transformControls.getRaycaster()
      const intersectingControls = raycaster.intersectObject(helper).length > 0
      const draggingControls = gltf?.transformControls?.isDragging
      if (intersectingControls || draggingControls) {
        return
      }
    }

    const selection = event.object?.parent
    const node = selection ? gltf.objectPartMap.get(selection) : null
    if (!selection || !selection.visible || !node) return

    selectable.handlers(node.uuid, { object: selection }).onSelectHover(event)
  }

  /*
   * Handler for when the pointer is pressed down on a part in the CAD model.
   */
  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()
    if (state.isRotating || state.isDragging) return
    if (gltf.transformControls) {
      const helper = gltf.transformControls.getHelper()
      const raycaster = gltf.transformControls.getRaycaster()
      const intersectingControls = raycaster.intersectObject(helper).length > 0
      const draggingControls = gltf?.transformControls?.isDragging
      if (intersectingControls || draggingControls) {
        return
      }
    }

    state.setState({ point: [event.point.x, event.point.y, event.point.z] })

    const wandSelected = state.wandSelected
    const selection = event.object?.parent
    const node = selection ? gltf.objectPartMap.get(selection) : null

    if (!selection || !selection.visible || !node) return

    //
    // Scroll part into view from the Assembly Tree
    //

    const el = document.getElementById(`tree-node-container-${node.uuid}`)
    el?.scrollIntoView({ behavior: 'smooth' })
    el?.classList.add('bg-primary-10', 'border', '!border-primary-50')
    setTimeout(() => {
      el?.classList.remove('bg-primary-10', 'border', '!border-primary-50')
    }, 1500)

    //
    // Set part appearance
    //
    const shouldSetColor = wandSelected && wandSelected !== WAND.TRANSPARENCY

    if (wandSelected) {
      if (shouldSetColor) {
        if (state.isTransparent(node.uuid)) gltf.resetTransparency([node.uuid])
        if (state.isColor(node.uuid, wandSelected)) gltf.resetColor(node.uuid)
        else gltf.setColor(node.uuid, wandSelected)
      } else {
        if (state.isColored(node.uuid)) gltf.resetColor(node.uuid)
        if (state.isTransparent(node.uuid)) gltf.resetTransparency([node.uuid])
        else gltf.setTransparency(node.uuid)
      }

      state.updateAppearance(node.uuid, wandSelected)
    }

    //
    // Select parts (for multi-select)
    //

    const shouldSelectPart = !wandSelected
    if (shouldSelectPart) {
      selectable.handlers(node.uuid, { object: selection }).onSelectStart(event)
    }
  }

  /*
   * Handler for when the pointer is released or leves a part in the CAD model.
   */
  const handlePointerUpAndLeave = (event: SelectionEvent) => {
    event.stopPropagation()
    if (gltf?.transformControls?.isDragging) return
    selectable.onSelectEnd(event)
  }

  return { handlePointerEnter, handlePointerDown, handlePointerUpAndLeave }
}
