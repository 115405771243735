import { useEffect } from 'react'
import { Mesh, LineSegments } from 'three'
import { useThree } from '@react-three/fiber'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { INTERACTION_LAYER, WIREFRAME_LAYER } from '@/pages/CADPage/constants'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
// #TODO: Use rawGLTF.parser.associations for loading progress

export const layerMap = {
  [Mesh.name]: INTERACTION_LAYER,
  [LineSegments.name]: WIREFRAME_LAYER,
}

export const useModelSetup = (
  gltf?: GLTFObject,
  options?: {
    mergeModelParts?: boolean
    computeBoundsTree?: boolean
  },
) => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const getThree = useThree((state) => state.get)

  useEffect(() => {
    if (!gltf || !assemblyTree) return

    const { camera, raycaster } = getThree()
    const mergeModelParts = options?.mergeModelParts ?? true
    const computeBoundsTree = options?.computeBoundsTree ?? true

    raycaster.layers.set(INTERACTION_LAYER)
    raycaster.layers.enable(INTERACTION_LAYER)
    camera.layers.enableAll()

    gltf.setup(assemblyTree, {
      mergeModelParts,
      computeBoundsTree,
      layerMap,
    })

    return () => {
      if (gltf) {
        gltf.cleanupModel({ computeBoundsTree })
      }
    }
  }, [gltf, assemblyTree, options, getThree])
}
