import React, { useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import { cn } from '@/utils'
import { Button } from './button'

interface FilesProps {
  onChange: (files: File[]) => void
  validateFile?: (file: File) => boolean
  error?: string
  maxFiles?: number
  placeholder?: string | React.ReactNode
  files: Array<File>
  isActive?: boolean
  testId?: string
}

export const Files = ({
  error,
  onChange,
  validateFile,
  placeholder = 'Upload a valid STEP file',
  files,
  isActive,
  testId,
}: FilesProps) => {
  return (
    <div className="p-2">
      <FileInput
        onChange={(inputFiles) => {
          onChange(Object.values(inputFiles))
        }}
        validateFile={validateFile}
        error={error}
        placeholder={placeholder}
        isActive={isActive}
        files={files}
        testId={testId}
      />
    </div>
  )
}

interface FileInputProps {
  onChange: (files: { [k: string]: File }) => void
  validateFile?: (file: File) => boolean
  error?: string
  placeholder?: string | React.ReactNode
  isActive?: boolean
  files: Array<File>
  testId?: string
}

export const FileInput = ({
  error,
  onChange,
  validateFile,
  placeholder = 'Upload a valid STEP file',
  isActive,
  files,
  testId,
}: FileInputProps) => {
  const [isOver, setIsOver] = useState(false)

  return (
    <>
      <label
        data-testid={testId}
        htmlFor="file"
        className={cn(
          'border rounded-full py-4 w-full flex items-center justify-center cursor-pointer hover:border-blue-500 !mt-2',
          {
            'border-cyan-700': isOver,
            'border-destructive': error,
            'bg-blue-50': isActive,
            'border-blue-500': isActive,
          },
        )}
        onDragEnter={(ev) => {
          ev.preventDefault()
          setIsOver(true)
        }}
        onDragLeave={(ev) => {
          ev.preventDefault()
          // This condition will be true when the drag leaves the parent for a child,
          // but false when the drag leaves the parent for somewhere else.
          if (ev.currentTarget.contains(ev.relatedTarget as Node)) return
          setIsOver(false)
        }}
        onDragOver={(ev) => ev.preventDefault()}
        onDrop={async (ev) => {
          ev.preventDefault()
          setIsOver(false)
          const files: { [k: string]: File } = {}

          const fileHandlesPromises = [...ev.dataTransfer.items]
            .filter((item) => item.kind === 'file')
            .map((item) => item.getAsFile())
          for await (const file of fileHandlesPromises) {
            if (file) {
              const isValid =
                typeof validateFile === 'function' ? validateFile(file) : true
              if (isValid) {
                files[file.name] = file
              }
            }
          }

          onChange(files)
        }}
      >
        <div
          className={cn('flex flex-col items-center flex-1 px-4', {
            'text-blue-500': isActive,
          })}
        >
          {files && files.length > 0 ? (
            <div className="my-1 flex flex-1 flex-col w-full">
              {files?.map((file, i) => {
                return (
                  <div
                    key={`${i}_${file.name}`}
                    className="flex items-center justify-center px-2 text-sm text-blue-500 flex-1 space-x-2"
                  >
                    <span className="space-x-2 max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {file.name}
                    </span>
                    <div className="flex items-center">
                      <Button
                        className="p-0"
                        variant="ghost"
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          const updatedFiles = files.filter(
                            (f) => f.name !== file.name,
                          )
                          onChange(
                            updatedFiles.reduce((accum, file) => {
                              accum[file.name] = file
                              return accum
                            }, {}),
                          )
                        }}
                      >
                        <TrashIcon className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <span className="text-sm">{placeholder}</span>
          )}
        </div>
      </label>
      <input
        className="hidden"
        type="file"
        id="file"
        name="file"
        multiple
        onChange={(ev) => {
          ev.preventDefault()
          const files: { [k: string]: File } = {}
          if (ev.target.files) {
            for (const file of ev.target.files) {
              const isValid =
                typeof validateFile === 'function' ? validateFile(file) : true
              if (isValid) {
                files[file.name] = file
              }
            }
            onChange(files)
          }
        }}
      />
      {error && (
        <div className="text-[0.8rem] font-medium text-destructive !mt-2">
          {error}
        </div>
      )}
    </>
  )
}
