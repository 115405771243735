import { useCallback, useMemo, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import CameraControlsImpl from 'camera-controls'
import { useShallow } from 'zustand/react/shallow'
import { Object3D, Object3DEventMap, Box3, Sphere, Clock } from 'three'

import { useCADPageStore, CONTROLS } from '@/state'

export const useCameraControls = () => {
  const clock = useMemo(() => new Clock(), [])
  const [updating, setUpdating] = useState(false)
  const controls = useThree((state) => state.controls)
  const fitting = useCADPageStore(useShallow((state) => state.fitting))
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const sceneCenter = useCADPageStore(useShallow((state) => state.sceneCenter))

  const fitToSphere = useCallback(
    async (object: Object3D<Object3DEventMap>, animate = true) => {
      if (fitting && sceneCenter && controls instanceof CameraControlsImpl) {
        const boundingBox = new Box3().setFromObject(object)
        const sphere = boundingBox.getBoundingSphere(new Sphere())
        setUpdating(true)
        await controls.setTarget(
          sceneCenter.x,
          sceneCenter.y,
          sceneCenter.z,
          false,
        )
        await controls.fitToSphere(sphere, animate)
      }
    },
    [controls, fitting, sceneCenter],
  )

  useFrame(() => {
    if (updating && controls instanceof CameraControlsImpl) {
      controls.update(clock.getDelta())
      if (!controls.active) {
        setState({ controlsId: CONTROLS.TRACKBALL_CONTROLS, fitting: false })
        setUpdating(false)
      }
    }
  })

  return { fitToSphere }
}
