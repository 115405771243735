import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { useDocumentState } from '@/state'

type CommentsButtonType = {
  isDisabled?: boolean
}
export const CommentsButton = ({ isDisabled }: CommentsButtonType) => {
  const commentsEnabled = useDocumentState((state) => state.commentsEnabled)
  const setCommentsEnabled = useDocumentState(
    (state) => state.setCommentsEnabled,
  )

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="rounded-md hover:bg-gray-100 m-0 h-full flex p-1 items-center justify-center min-w-[50px]"
            style={{ backgroundColor: commentsEnabled ? '#D2E8FF' : undefined }}
            onClick={() => setCommentsEnabled(!commentsEnabled)}
            disabled={isDisabled}
            data-testid="comments-button"
          >
            <span className="flex items-center flex-col space-y-0.5 h-full justify-between">
              <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 stroke-2" />
              <span className="text-xs">Comments</span>
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{commentsEnabled ? 'Close' : 'Open'} comments</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CommentsButton
