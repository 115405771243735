import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useMemo,
  useRef,
} from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { TableCell, TableHead } from '@/components/ui/table'
import {
  Table,
  TableColumn,
  TableRow,
  TemplateAttribute,
  useCreateTable,
  useDeleteTable,
  useUpdateTable,
  useUpdateTemplateElement,
} from '@/services/hooks/template_attributes'
import type { DocumentPage } from '@/lib/api/client'
import { EditableTable, createNewColumn } from './EditableTable'
import { useDocumentState } from '@/state/document'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useUpdateCadVersionMutation } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useStepBOM } from './OperationDocument/hooks/useBOM'
import { useToast } from '@/components/ui/use-toast'
import { Textarea } from '@/components/ui/textarea'

export type PartsTableData = {
  partName: any
  quantity: number | string
  [key: string]: any
}

type PartsTableProps = {
  isReadOnly?: boolean
  documentPage: DocumentPage
  partsTableTemplate?: TemplateAttribute<Table>
}

export const PartsTable = ({
  isReadOnly = false,
  documentPage,
  partsTableTemplate,
}: PartsTableProps) => {
  const isManual = useMemo(
    () => partsTableTemplate?.template_values.isManual,
    [partsTableTemplate?.template_values.isManual],
  )
  const draggableId = useMemo(
    () => partsTableTemplate?.id || 'parts-table',
    [partsTableTemplate?.id],
  )
  const tableType = useMemo(() => 'parts-table', [])

  const partsInOperation = useStepBOM({
    documentPageId: documentPage.id,
  })

  const initialTableData = useMemo(
    () =>
      isManual
        ? []
        : partsInOperation.map<PartsTableData>((part) => ({
            partName: part,
            quantity: part.quantity,
          })),
    [partsInOperation, isManual],
  )

  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )

  const selectTable = useCallback(() => {
    setSelectedElement({
      documentPageId: documentPage.id as string,
      id: draggableId,
      isRemovable: false,
    })
  }, [documentPage.id, draggableId, setSelectedElement])

  const initialColumns = useMemo<ColumnDef<PartsTableData>[]>(
    () => [
      {
        accessorKey: 'partName',
        header: 'Part Name',
        size: 340,
        enableResizing: true,
        cell: ({ getValue, table, column }) =>
          isReadOnly ? (
            <TableCell
              className="items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 bg-white/70"
              style={{
                position: 'relative',
                width: column.getSize(),
              }}
            >
              {getValue<any>().partName}
            </TableCell>
          ) : (
            <NameCell
              documentPageId={documentPage.id as string}
              getValue={getValue}
              selectTable={selectTable}
              tableId={(table.options.meta as any).tableId}
              width={column.getSize()}
            />
          ),
      },
      {
        accessorKey: 'quantity',
        header: 'Qty',
        size: 60,
        enableResizing: true,
        cell: ({ getValue, column }) => (
          <TableCell
            className="items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 bg-white/70"
            style={{
              position: 'relative',
              width: column.getSize(),
            }}
          >
            <span className="px-1">{getValue<string>()}</span>
          </TableCell>
        ),
      },
    ],
    [documentPage.assembly_group_id, selectTable],
  )

  const [tableData, setTableData] = useState(() => [...initialTableData])
  const [columnsDef, setColumnsDef] = useState(() => [...initialColumns])
  const [columnsData, setColumnsData] = useState(
    initialColumns.reduce((acc, column) => {
      acc[(column as any).accessorKey] = column.header
      return acc
    }, {}),
  )

  useEffect(() => {
    setTableData(initialTableData)
  }, [initialTableData])

  const partsCustomColumns = useMemo(
    () => partsTableTemplate?.template_values.columns || [],
    [partsTableTemplate],
  )
  const partsCustomRows = useMemo(
    () => partsTableTemplate?.template_values.rows || [],
    [partsTableTemplate],
  )

  const tablePosition = useMemo(
    () => partsTableTemplate?.template_values.position,
    [partsTableTemplate?.template_values.position],
  )
  const columnsSize = useMemo(
    () => partsTableTemplate?.template_values.columnsSize,
    [partsTableTemplate?.template_values.columnsSize],
  )

  const { mutateAsync: updateTable } = useUpdateTable()
  const { mutateAsync: createTable } = useCreateTable()
  const { mutateAsync: deleteTable } = useDeleteTable()
  const { mutateAsync: updateTemplate } = useUpdateTemplateElement({
    documentPageId: documentPage.id as string,
  })

  const saveTableData = useCallback(
    async ({
      columns,
      rows,
    }: {
      columns?: Array<TableColumn>
      rows?: Array<TableRow>
    }) => {
      if (partsTableTemplate?.id) {
        const tableData: Table = {
          type: tableType,
        }

        if (columns) {
          tableData.columns = columns
        }

        if (rows) {
          tableData.rows = rows
        }

        await updateTable({
          templateAttributeId: partsTableTemplate?.id,
          documentPageId: documentPage.id as string,
          tableData,
        })
        return
      }

      await createTable({
        documentPageId: documentPage.id as string,
        tableData: {
          type: tableType,
          columns,
          rows,
        },
      })
    },
    [
      documentPage.id,
      tableType,
      partsTableTemplate?.id,
      updateTable,
      createTable,
    ],
  )

  const [isDeleting, setIsDeleting] = useState(false)

  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPage.id &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPage.id, draggableId],
  )

  const deleteTableHandler = useCallback(async () => {
    setIsDeleting(true)
    if (partsTableTemplate?.id && isManual)
      await deleteTable(partsTableTemplate?.id)

    if (!isManual) {
      const cleanPayload = {
        removed: true,
        type: tableType,
      }
      await updateTemplate({
        payload: cleanPayload,
        data_type: 'table' as any,
        templateAttributeId: partsTableTemplate?.id as string,
      })
    }

    setIsDeleting(false)
  }, [
    isManual,
    deleteTable,
    setIsDeleting,
    partsTableTemplate?.id,
    tableType,
    updateTemplate,
  ])

  const onRemove = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        await deleteTableHandler()
      }
    },
    [deleteTableHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  useEffect(() => {
    if (partsCustomColumns?.length) {
      setColumnsData((prev) => ({
        ...prev,
        ...partsCustomColumns.reduce(
          (accum, customColumn) => ({
            ...accum,
            ...customColumn,
          }),
          {},
        ),
      }))

      partsCustomColumns.forEach((column) => {
        Object.keys(column).forEach((accessorKey) => {
          const columnDef = createNewColumn(
            accessorKey,
            onCellChange,
            selectTable,
            isReadOnly,
            documentPage.id as string,
          ) as ColumnDef<PartsTableData>
          setColumnsDef((prev) => [...prev, columnDef])
        })
      })
    }

    /* This is needed because the app is running in strict mode so it runs this hook twice */
    return () => {
      setColumnsDef(() => [...initialColumns])
    }
  }, [partsCustomColumns])

  useEffect(() => {
    let customColumnsData: Array<TableRow> = []
    let customRows = partsCustomRows

    if (partsCustomColumns?.length) {
      customColumnsData = partsCustomRows.slice(0, initialTableData.length)
    }

    if (customColumnsData?.length) {
      customRows = partsCustomRows.slice(
        initialTableData.length,
        partsCustomRows.length,
      )
    }

    setTableData((prev) => [
      ...prev.map((row, i) => {
        if (customColumnsData?.length) {
          return {
            ...row,
            ...((customColumnsData[i] || {}) as any),
          }
        }
        return row
      }),
      ...(customRows || []),
    ])

    return () => {
      setTableData(() => [...initialTableData])
    }
  }, [partsCustomRows, partsCustomColumns, initialTableData])

  const onCellChange = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((prev) =>
        prev.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row,
        ),
      )
    },
    [setTableData],
  )

  const onHeaderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, accessorKey: string) => {
      const target = event.target
      const value = target.value

      setColumnsData((prev) => ({
        ...prev,
        [accessorKey]: value,
      }))
    },
    [],
  )

  const savePartsTableData = useCallback(async () => {
    const cleanColumns = columnsDef
      .filter((column) =>
        ((column as any).accessorKey as string).includes('custom-column'),
      )
      .reduce((accum, colDef: any) => {
        const columnAccessor = Object.keys(columnsData).find(
          (accessorKey) => accessorKey === colDef.accessorKey,
        )

        if (!columnAccessor) return accum

        const column = columnsData[columnAccessor]

        return [
          ...accum,
          {
            [columnAccessor]: column,
          },
        ]
      }, [] as Array<any>)

    let filteredTableData = tableData
      .slice(0, initialTableData.length)
      .map((row) =>
        Object.keys(row).reduce((accum, key) => {
          if (key.includes('custom-column')) accum[key] = row[key]
          return accum
        }, {} as any),
      )
      .filter((row) => Object.keys(row).length > 0)

    filteredTableData = [
      ...filteredTableData,
      ...tableData.slice(initialTableData.length, tableData.length),
    ]

    await saveTableData({
      columns: cleanColumns,
      rows: filteredTableData,
    })
  }, [
    tableData,
    columnsData,
    columnsDef,
    initialTableData.length,
    saveTableData,
  ])

  const addNewColumn = useCallback(
    async ({
      prevAccessorKey,
      newColumn,
    }: {
      prevAccessorKey: string
      newColumn: ColumnDef<any>
    }) => {
      const newColumnAccessor = (newColumn as { accessorKey: string })
        .accessorKey
      const columnBefore =
        columnsDef.findIndex(
          (column) => (column as any).accessorKey === prevAccessorKey,
        ) || columnsDef.length - 1
      const newColumns = columnsDef
        .reduce((accum, columnDef: any, index) => {
          const columnAccessor = columnDef.accessorKey
          const column = {
            [columnAccessor]: columnsData[columnAccessor],
          }
          if (index === columnBefore) {
            return [
              ...accum,
              column,
              {
                [newColumnAccessor]: '',
              },
            ]
          }

          return [...accum, column]
        }, [] as any)
        .slice(initialColumns.length)

      const updatedRows = [
        ...tableData.slice(0, initialTableData.length).map((row) => {
          const rowKeys = Object.keys(row).filter((key) =>
            key.includes('custom-column'),
          )
          return rowKeys.reduce(
            (accum, key) => {
              accum[key] = row[key]
              return accum
            },
            { [newColumnAccessor]: '' } as any,
          )
        }),
        ...tableData
          .slice(initialTableData.length, tableData.length)
          .map((row) => {
            return { ...row, [newColumnAccessor]: '' }
          }),
      ]

      await saveTableData({
        columns: newColumns,
        rows: updatedRows,
      })
    },
    [columnsDef, columnsData, tableData],
  )

  const removeColumn = useCallback(
    async (accessorKey: string) => {
      const columnBefore = columnsDef.findIndex(
        (column) => (column as any).accessorKey === accessorKey,
      )

      if (columnBefore === -1) return
      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore),
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.filter((row) => Object.keys(row).includes(accessorKey)),
      )
      setColumnsData((prev) => {
        const newColumns = { ...prev }
        delete newColumns[accessorKey]
        return newColumns
      })

      const filteredRows = partsCustomRows
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if ((currAccessorKey as string) !== accessorKey) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)

      const filteredColumns = columnsDef
        .filter(
          (column) =>
            ((column as any).accessorKey as string).includes('custom-column') &&
            ((column as any).accessorKey as string) !== accessorKey,
        )
        .map((column) => {
          const accessorKey = (column as any).accessorKey
          return { [accessorKey]: columnsData[accessorKey] }
        })

      await saveTableData({
        columns: filteredColumns,
        rows: filteredRows,
      })
    },
    [
      columnsDef,
      columnsData,
      tableData,
      partsCustomRows,
      setColumnsDef,
      saveTableData,
    ],
  )

  const removeRow = useCallback(
    async (rowIndex) => {
      let offset = initialTableData.length

      if (partsCustomColumns?.length) {
        offset = 0
      }

      const filteredRows = partsCustomRows.filter(
        (_, index) => index !== rowIndex - offset,
      )

      setTableData((prev) => prev.filter((_, index) => index !== rowIndex))
      await saveTableData({
        rows: filteredRows,
        columns: partsCustomColumns,
      })
    },
    [
      tableData,
      partsCustomRows,
      partsCustomColumns,
      initialTableData.length,
      setTableData,
      saveTableData,
    ],
  )

  const addNewRow = useCallback((newRow: any) => {
    setTableData((prev) => [...prev, newRow])
  }, [])

  useEffect(() => {
    if (columnsSize) {
      setColumnsDef((prevColumns) =>
        prevColumns.map((column) => {
          const accessor = (column as any).accessorKey
          const size = (columnsSize as any)[accessor]
          if (size) {
            return {
              ...column,
              size,
            }
          }
          return column
        }),
      )
    }
  }, [columnsSize])

  return (
    !partsTableTemplate?.template_values.removed && (
      <EditableTable
        saveTableData={savePartsTableData}
        onCellChange={onCellChange}
        removeRow={removeRow}
        addRow={addNewRow}
        onHeaderChange={onHeaderChange}
        saveCustomColumns={savePartsTableData}
        removeColumn={removeColumn}
        addColumn={addNewColumn}
        documentPageId={documentPage?.id as string}
        tableData={tableData}
        defaultRows={initialTableData.length}
        defaultColumns={initialColumns.length}
        columnsDef={columnsDef as any}
        columnsData={columnsData}
        maxColumns={4}
        positionData={tablePosition}
        columnsSize={columnsSize || {}}
        templateId={partsTableTemplate?.id as string}
        isDeleting={isDeleting}
        id={draggableId}
        type={tableType}
        isReadOnly={isReadOnly}
        additionalColumns={[
          <TableHead
            key="table-head--id"
            className="relative border-x border-solid border-gray-200 border-collapse text-xs h-auto py-1"
            style={{
              width: 35,
            }}
          >
            Id
          </TableHead>,
        ]}
        testId="parts-table"
      />
    )
  )
}

type NameCellProps = {
  getValue: CellContext<PartsTableData, unknown>['getValue']
  selectTable: (tableId: string) => void
  tableId: string
  documentPageId: string
  width: number
}
const NameCell = ({
  getValue,
  selectTable,
  tableId,
  documentPageId,
  width,
}: NameCellProps) => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { toast } = useToast()
  const [hasChanged, setHasChanged] = useState(false)
  const part = getValue<any>()
  const isString = useMemo(() => typeof part === 'string', [part])
  const initialPartName = isString ? part : part?.partName
  const [partName, setPartName] = useState(initialPartName || '')

  useEffect(() => {
    setPartName(initialPartName || '')
  }, [initialPartName])

  const { mutate: updateCadVersion } = useUpdateCadVersionMutation()
  const { data: cadData, refetchCadVersion } = useCADQuery()

  const cadVersionId = useMemo(
    () => cadData?.version?.id as string,
    [cadData?.version],
  )
  const documentVersionId = useMemo(
    () => cadData?.documentVersion?.id as string,
    [cadData?.documentVersion],
  )
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHasChanged(true)
    const updatedValue = event.target.value
    setPartName(updatedValue)
  }

  const handlePartNameChange = useCallback(async () => {
    if (isString || !assemblyTree) return
    if (!partName) {
      setPartName(initialPartName)
      toast({
        title: `Can't rename part ${initialPartName}`,
        description: 'Part name cannot be empty',
        variant: 'destructive',
      })
      return
    }
    const tree = { ...assemblyTree }
    const UUIDs = part.UUIDs
    UUIDs.forEach((uuid) => {
      const node = tree.nodes?.find((node) => node.uuid === uuid)
      if (node) {
        node.display_name = partName
      }
    })
    if (assemblyTree && cadVersionId && documentVersionId) {
      await updateCadVersion({
        cadVersionId,
        documentVersionId,
        assemblyTree: tree,
      })
      refetchCadVersion()
    }
  }, [
    isString,
    assemblyTree,
    part.UUIDs,
    cadVersionId,
    documentVersionId,
    partName,
    updateCadVersion,
    refetchCadVersion,
  ])

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const adjustHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [textareaRef])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries && entries.length) {
        adjustHeight()
      }
    })

    if (textareaRef.current) {
      observer.observe(textareaRef.current)
    } else {
      observer.disconnect()
    }

    return () => {
      observer.disconnect()
    }
  }, [textareaRef, adjustHeight])

  useEffect(() => {
    adjustHeight()
  }, [partName, adjustHeight])

  const handleOnBlur = useCallback(async () => {
    if (hasChanged) {
      await handlePartNameChange()
      setHasChanged(false)
    }
  }, [hasChanged, handlePartNameChange])

  return (
    <TableCell
      id={`part-${documentPageId}-${initialPartName.replace(/\s/g, '-')}`}
      className="border-x border-solid border-gray-200 border-collapse py-0 bg-white/70"
      style={{
        position: 'relative',
        width,
      }}
    >
      <Textarea
        className="border-none shadow-none m-0 p-1 text-xs min-h-4 overflow-hidden resize-none"
        value={partName}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onMouseDownCapture={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          selectTable(tableId)
        }}
        data-testid="parts-table-cell-input"
        ref={textareaRef}
        rows={1}
      />
    </TableCell>
  )
}
