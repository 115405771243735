import { RawAssemblyTree, ASMTreeNode } from '../assembly'

export const getInstances = (
  assemblyTree: RawAssemblyTree,
  uuids: string | string[],
): string[] => {
  const uuidArray = Array.isArray(uuids) ? uuids : [uuids]
  return uuidArray
    .map((uuid) => assemblyTree.nodes.find((n) => n.uuid === uuid))
    .filter((node) => node !== undefined)
    .map((node) => node.instance)
}

export const getDescendants = (
  assemblyTree: RawAssemblyTree,
  node: ASMTreeNode,
): ASMTreeNode[] => {
  const children = node.children.map((uuid) =>
    assemblyTree.nodes.find((n) => n.uuid === uuid),
  )
  return children
    .filter((child) => child !== undefined)
    .flatMap((child) => [child, ...getDescendants(assemblyTree, child)])
}

export const getAncestors = (
  assemblyTree: RawAssemblyTree,
  node: ASMTreeNode,
): ASMTreeNode[] => {
  const parent = assemblyTree.nodes.find((n) => n.uuid === node.parent)
  if (!parent) return []
  return [parent, ...getAncestors(assemblyTree, parent)]
}
