import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  MutableRefObject,
} from 'react'
import { cn } from '@/utils'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import Mention from '@tiptap/extension-mention'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import type {
  EditorEvents,
  Extensions,
  Editor as TipTapEditor,
} from '@tiptap/react'
import type { EditorAttributes } from './lib/types'

import { Marker } from './tiptap/Marker'
import { Icons } from './tiptap/Icons'
import { SlashCommand } from './tiptap/SlashCommand'
import { Submit } from './tiptap/Submit'
import { EditorMenuBar } from './EditorMenuBar'
import { Markup } from './Markup'

import iconSuggestion from './IconSuggestion'
import mentionSuggestion from './MentionSuggestion'
import { CUSTOM_ICONS, ExtensionsEnum } from './constants'

const baseExtensions = [
  // @ts-expect-error - types are wrong
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
]

const EXTENSIONS_MAP = {
  [ExtensionsEnum.ICONS]: Icons.configure({
    emojis: CUSTOM_ICONS,
    suggestion: iconSuggestion,
    HTMLAttributes: {
      class: 'annotation-icon',
    },
  }),
  [ExtensionsEnum.LINK]: Link.configure({
    openOnClick: true,
    autolink: true,
    linkOnPaste: true,
  }),
  [ExtensionsEnum.COLOR]: Color.configure({
    types: [TextStyle.name, ListItem.name],
  }),
  [ExtensionsEnum.UNDERLINE]: Underline,
  [ExtensionsEnum.TEXT_ALIGN]: TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  [ExtensionsEnum.MARKER]: Marker.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'slash-command-pill',
    },
  }),
  [ExtensionsEnum.HIGHLIGHT]: Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlight',
    },
  }),
  [ExtensionsEnum.TASK_LIST]: TaskList,
  [ExtensionsEnum.TASK_ITEM]: TaskItem.configure({
    nested: true,
  }),
  [ExtensionsEnum.MENTION]: Mention.configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: mentionSuggestion,
  }),
}

type EditorProps = {
  className?: string
  documentPageId: string
  documentId: string
  projectId: string
  disabled?: boolean
  content: string
  onUpdate: (props: EditorEvents['update']) => void
  onSubmit?: (content?: string) => void
  onBlur?: (props: { editor: TipTapEditor }) => void
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  placeholder?: string
  isDragging?: boolean
  isAddingAnnotation?: boolean
  menuScale?: number
  updateBomWithSlashCommands?: boolean
  extensionsList?: Array<ExtensionsEnum>
  characterCountLimit?: number
  isTransparent?: boolean
  editorRef?: MutableRefObject<TipTapEditor | null>
}
export const Editor = ({
  className,
  documentId,
  documentPageId,
  projectId,
  disabled,
  content,
  onUpdate,
  onSubmit,
  onBlur,
  onClick = () => {},
  placeholder,
  isDragging = false,
  isAddingAnnotation = false,
  menuScale = 1,
  updateBomWithSlashCommands,
  characterCountLimit,
  isTransparent,
  editorRef,
  extensionsList = [
    ExtensionsEnum.ICONS,
    ExtensionsEnum.LINK,
    ExtensionsEnum.COLOR,
    ExtensionsEnum.UNDERLINE,
    ExtensionsEnum.TEXT_ALIGN,
    ExtensionsEnum.MARKER,
    ExtensionsEnum.HIGHLIGHT,
    ExtensionsEnum.TASK_LIST,
    ExtensionsEnum.TASK_ITEM,
    ExtensionsEnum.MENTION,
    ExtensionsEnum.SLASH_COMMAND,
  ],
}: EditorProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  const extensions = useMemo(() => {
    const e: Extensions = [...baseExtensions]

    extensionsList.forEach((extension) => {
      if (EXTENSIONS_MAP[extension]) {
        e.push(EXTENSIONS_MAP[extension])
      }
    })

    if (extensionsList.includes(ExtensionsEnum.SLASH_COMMAND)) {
      e.push(
        SlashCommand.configure({
          slashComponentProps: {
            updateBomOnSelect: updateBomWithSlashCommands,
          },
        }),
      )
    }
    if (placeholder) {
      e.push(
        Placeholder.configure({
          placeholder,
        }),
      )
    }

    if (onSubmit) {
      e.push(
        Submit.configure({
          onSubmit,
        }),
      )
    }

    if (characterCountLimit) {
      e.push(
        CharacterCount.configure({
          limit: characterCountLimit,
        }),
      )
    }

    return e
  }, [placeholder, extensionsList, characterCountLimit, onSubmit])

  const editorAttributes: EditorAttributes = {
    documentPageId,
    projectId,
    documentId,
    class: cn('tiptap ProseMirror', {
      '!bg-transparent': isTransparent,
    }),
  }

  const editor = useEditor({
    extensions,
    content,
    editable: !isAddingAnnotation,
    onFocus: ({ event }) => {
      if (isAddingAnnotation) {
        const target = event.target as any
        target?.blur()
      }
    },
    editorProps: {
      attributes: editorAttributes,
    },
    onUpdate,
  })

  useEffect(() => {
    if (editorRef) {
      editorRef.current = editor
    }
  }, [editorRef, editor])

  const handleBlur = useCallback(
    (event) => {
      if (ref.current && ref.current.contains(event.relatedTarget)) {
        return
      }
      setShowMenu(false)

      if (editor && typeof onBlur === 'function') {
        onBlur({ editor })
      }
    },
    [editor, onBlur],
  )

  if (!editor) {
    return null
  }

  if (disabled) {
    return (
      <Markup
        className="min-h-[60px] h-full w-full px-3 py-2 text-sm flex flex-col space-y-2 tiptap"
        html={editor.getHTML()}
      />
    )
  }

  return (
    <div className="relative h-full w-full" ref={ref}>
      <EditorContent
        onFocus={() => setShowMenu(true)}
        onBlur={handleBlur}
        onClick={onClick}
        className={cn(
          'cursor-text flex min-h-[45px] h-full w-full rounded-md bg-white px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 flex-1 overflow-auto',
          className,
        )}
        editor={editor}
        placeholder={placeholder}
        data-testid="tip-tap-editor"
      >
        {showMenu && !isDragging && (
          <>
            <div
              className="absolute inset-x-0 mx-auto flex justify-center"
              style={{
                top: '-28px',
                transform: `scale(${menuScale})`,
              }}
            >
              <EditorMenuBar editor={editor} extensionsList={extensionsList} />
            </div>
          </>
        )}
      </EditorContent>
    </div>
  )
}
