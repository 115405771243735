import { Suspense, useEffect, useMemo } from 'react'
import { MathUtils } from 'three'
import { Canvas } from '@react-three/fiber'
import { Environment, SpotLight } from '@react-three/drei'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { Model } from '../CADModel'
import { CADLoading } from '../CADLoading'
import { useCADPageStore, WAND } from '@/state'
import { useShallow } from 'zustand/react/shallow'
import { ScreenshotOverlay } from '../ScreenshotOverlay'
import { ScreenshotPanelButton } from '../ScreenshotPanelButton'
import { CADEditorTip } from './CADEditorTip'
import { GizmoHelper, GizmoViewcube } from '@/lib/cad'

import hdrImage from '@/assets/potsdamer_platz_1k_micro.hdr?url'
import { useCADQuery } from '@/services/queries/cads'
import { ReuploadCadButton } from '@/pages/DocumentPage/components/ReuploadCadButton'
import { COLOR_OPTIONS } from '@/constants'
import { eventManagerFactory } from '../CADModel/hooks/useCanvasEventManager'
import { cn } from '@/utils'
import { Button } from '@/components/ui/button'
import { CameraControls } from './CameraControls'

export const CADEditor = () => {
  const { isLoading, data } = useCADQuery()
  const showAxis = useCADPageStore((state) => state.showAxis)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const resetEditMode = useCADPageStore(
    useShallow((state) => state.resetEditMode),
  )
  const isEditModeActive = useCADPageStore(
    useShallow((state) => state.isEditModeActive),
  )

  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const wandSelected = useCADPageStore((state) => state.wandSelected)

  const cadVersionId = data.version?.id
  const selectFromCad = useMemo(
    () => activeOperationStep?.selectFromCad,
    [activeOperationStep?.selectFromCad],
  )
  const currentColorName = useMemo(() => {
    if (wandSelected === WAND.TRANSPARENCY) {
      return WAND.TRANSPARENCY
    }

    if (wandSelected) {
      const selectedColor = COLOR_OPTIONS.find(
        (color) => color.value === parseInt(wandSelected.slice(1), 16),
      )
      return selectedColor?.name
    }
  }, [wandSelected])

  useEffect(() => {
    resetEditMode()
  }, [resetEditMode])

  if (data.hasNoDownloadUrl && !isLoading) {
    return (
      <div className="flex flex-col mt-16 items-center">
        <ExclamationTriangleIcon className="h-8 w-8 text-red-500" />
        <h1 className="text-lg">Uploading CAD file was unsuccessful.</h1>
        <h2 className="text-lg">Please attempt to upload again.</h2>
        <div className="mt-6">
          {cadVersionId && <ReuploadCadButton cadVersionId={cadVersionId} />}
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full h-full pl-4">
      <div
        className={cn(
          'relative w-full h-full border-[3px] rounded-md border-transparent',
          {
            'border-green-400': isEditModeActive && !selectFromCad,
            'border-purple-600': selectFromCad,
          },
        )}
      >
        {isEditModeActive && (
          <Button
            variant="outline"
            size="sm"
            className={cn('absolute top-0 right-0 bg-white border-2 z-50 h-5', {
              'border-green-400': isEditModeActive && !selectFromCad,
              'border-purple-600': selectFromCad,
              'text-green-400': isEditModeActive && !selectFromCad,
              'text-purple-600': selectFromCad,
              'hover:text-green-400': !selectFromCad,
              'hover:text-purple-600': selectFromCad,
            })}
            onClick={resetEditMode}
            style={{ translate: '-30% -50%' }}
          >
            ESC
          </Button>
        )}
        <Canvas
          id="cad-canvas"
          data-testid="cad-page-canvas"
          events={eventManagerFactory}
          className={currentColorName ? `cursor-wand__${currentColorName}` : ''}
          fallback={<div>Loading...</div>}
          gl={{
            toneMappingExposure: 0.8,
            preserveDrawingBuffer: true,
            localClippingEnabled: true,
            antialias: true,
            stencil: true,
          }}
          performance={{ min: 0.5 }}
          dpr={[1, 2]}
          onPointerDown={() => setCadPageState({ isRotating: true })}
          onPointerUp={() => setCadPageState({ isRotating: false })}
        >
          <Suspense fallback={<CADLoading />}>
            <CADEditorTip />
            <ScreenshotPanelButton />
            <ambientLight intensity={0.8} />
            <Model />
            <axesHelper args={[0.2]} visible={showAxis} />
          </Suspense>
          <Environment files={hdrImage} />
          <SpotLight
            distance={50}
            intensity={100}
            angle={MathUtils.degToRad(45)}
            color={0xffffff}
            position={[0, -Math.PI, 0]}
            volumetric={false}
          />
          <CameraControls />
          <GizmoHelper alignment="bottom-left" margin={[80, 180]}>
            <GizmoViewcube color="#e1e1e6" textColor="#000000" />
          </GizmoHelper>
          <ScreenshotOverlay />
        </Canvas>
      </div>
    </div>
  )
}
