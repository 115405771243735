import * as Sentry from '@sentry/browser'
import { validate } from 'uuid'

export function isValidUUID(uuid: string): boolean {
  if (validate(uuid)) {
    return true
  } else {
    Sentry.getCurrentScope().setExtras({ invalidUUID: uuid })
    Sentry.captureException(new Error('Invalid UUID'))
    return false
  }
}
