import { cn } from '@/utils'
import { useCallback, useMemo, useState } from 'react'

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu'
import { HidePartButton } from '@/pages/CADPage/components/AssemblyTree/HidePartButton'

import { SelectionEvent, useSelectable } from '../../hooks'
import type { GLTFObject } from '@/lib/cad/GLTFObject'
import { type ASMTreeNode } from '@/state'
import { EditableField } from '@/components/core/EditableField'
import {
  useAssemblyTreeQuery,
  useUpdateCadVersionMutation,
} from '../../queries'
import { useCADQuery } from '@/services/queries/cads'

export const StepPart = ({
  part,
  gltf,
  isSelected,
  selectFromCad,
  onMouseDown,
  onDelete,
  isReadOnly,
}: {
  part: ASMTreeNode
  gltf: GLTFObject
  stepIsActive: boolean
  isSelected: boolean
  selectFromCad: boolean
  onMouseDown: (e: SelectionEvent) => void
  onDelete: (partUUID: string) => void
  isReadOnly?: boolean
}) => {
  const selectable = useSelectable({})
  const [isHiddenButtonVisible, setIsHiddenButtonVisible] = useState(false)
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { data: cadData, refetchCadVersion } = useCADQuery()
  const { mutate: updateCadVersion } = useUpdateCadVersionMutation()

  const cadVersionId = useMemo(
    () => cadData?.version?.id as string,
    [cadData?.version],
  )
  const documentVersionId = useMemo(
    () => cadData?.documentVersion?.id as string,
    [cadData?.documentVersion],
  )

  const handlePartRename = useCallback(
    async (partName: string) => {
      if (!assemblyTree) return
      const tree = { ...assemblyTree }
      const node = tree.nodes.find((n) => n.uuid === part.uuid)
      if (!node) return
      node.display_name = partName

      if (assemblyTree && cadVersionId && documentVersionId) {
        await updateCadVersion({
          cadVersionId,
          documentVersionId,
          assemblyTree: tree,
        })
        await refetchCadVersion()
      }
    },
    [
      assemblyTree,
      part,
      cadVersionId,
      documentVersionId,
      updateCadVersion,
      refetchCadVersion,
    ],
  )

  return (
    <ContextMenu>
      <ContextMenuTrigger
        disabled={isReadOnly}
        onMouseDown={onMouseDown}
        onMouseOver={(e) => {
          e.stopPropagation()
          setIsHiddenButtonVisible(true)
          selectable.handlers(part.uuid).onSelectHover(e)
        }}
        onMouseOut={(e) => {
          e.stopPropagation()
          setIsHiddenButtonVisible(false)
          selectable.onSelectEnd(e)
        }}
        onMouseUp={(e) => {
          e.stopPropagation()
          selectable.onSelectEnd(e)
        }}
      >
        <div
          id={`step-part-diplay-name-${part.uuid}`}
          className={cn(
            'flex flex-1 items-center justify-between font-semibold py-1 hover:bg-primary-20 cursor-pointer rounded-lg pr-3',
            {
              'bg-primary-20': isSelected,
            },
            // blend selected parts with active step in selectFromCAD mode
            {
              'bg-purple-50': isSelected && selectFromCad,
            },
            // set cursor to disabled when part is already attached to step
            {
              'hover:bg-purple-80 cursor-not-allowed':
                selectFromCad && isSelected,
            },
            {
              'hover:bg-white cursor-not-allowed': selectFromCad && !isSelected,
            },
          )}
          data-testid="step-part"
        >
          <EditableField
            value={part.display_name}
            onSaveHandler={handlePartRename}
            textClassNames="pl-[20px]"
            inputClassNames="ml-[20px]"
          />
          <HidePartButton
            node={part}
            gltf={gltf}
            isVisible={isHiddenButtonVisible}
          />
        </div>
      </ContextMenuTrigger>
      {!isReadOnly && !selectFromCad && (
        <ContextMenuContent>
          <ContextMenuItem
            onMouseDown={(e) => {
              e.stopPropagation()
              onDelete(part.uuid)
            }}
          >
            Remove
          </ContextMenuItem>
        </ContextMenuContent>
      )}
    </ContextMenu>
  )
}
