import { useShallow } from 'zustand/react/shallow'
import { useCallback, useEffect } from 'react'

import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCADPageStore } from '@/state/cad'
import { useGetInclusiveGroupIds } from '@/services/hooks/steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const useCrossSection = () => {
  const inclusiveGroupIds = useGetInclusiveGroupIds()
  const { data } = useDocumentPageQuery()

  const planes = useCADPageStore(useShallow((state) => state.clippingPlanes))
  const sectioning = useCADPageStore(useShallow((state) => state.sectionState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()

  const activeClippingPlane = 0
  const documentType = data?.documentType

  const updateCrossSectionMap = useCallback(() => {
    if (!assemblyTree || !documentType) return

    const parts = assemblyTree.nodes.map((node) => node.uuid)

    const { crossSectionMap, exclusiveCrossSectionMap } = getCadPageState()

    // Inclusive parts list
    const includedParts =
      documentType === 'work_instructions' &&
      inclusiveGroupIds &&
      inclusiveGroupIds.length > 0
        ? inclusiveGroupIds
        : parts
    crossSectionMap[activeClippingPlane] = includedParts

    // Exclusive parts list
    const excludedParts = parts.filter((part) => !includedParts.includes(part))
    exclusiveCrossSectionMap[activeClippingPlane] = excludedParts

    setCadPageState({ crossSectionMap, exclusiveCrossSectionMap })
  }, [
    assemblyTree,
    documentType,
    getCadPageState,
    inclusiveGroupIds,
    setCadPageState,
  ])

  useEffect(() => {
    updateCrossSectionMap()
  }, [planes, sectioning, inclusiveGroupIds, updateCrossSectionMap])

  return updateCrossSectionMap
}
