import { Object3D } from 'three'
import { useCallback } from 'react'
import { ThreeEvent } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'

import { ExplosionsState, useCADPageStore } from '@/state/cad'
import { Step } from '@/services/queries/operation_steps/types'
import { useCADQuery } from '@/services/queries/cads'
import { useAssemblyTreeQuery } from '../queries'
import { HIGHLIGHT_BLUE, HIGHLIGHT_PURPLE } from '@/constants'
import { useOperationSteps } from '@/services/queries/operation_steps'

export type SelectionEvent =
  | React.MouseEvent<HTMLSpanElement, MouseEvent>
  | ThreeEvent<PointerEvent>

export interface SelectableOptions {
  onRangeSelect?: (uuids: string[]) => void
  onMultiSelect?: (uuid: string) => void
  onSelect?: (
    uuid: string,
    options?: { object?: Object3D; step?: Step },
  ) => void
  onReset?: (uuid: string, object?: Object3D) => void
  onRangeDeselect?: (uuid: string, object?: Object3D) => void
}

export interface Selectable {
  size: number
  selected: string[]
  select: (uuid: string) => void
  reset: () => void
  isSelected: (uuid: string) => boolean
  handlers: (
    uuid: string,
    options?: { object?: Object3D; step?: Step },
  ) => {
    onSelectHover: (e: SelectionEvent) => void
    onSelectStart: (e: SelectionEvent) => void
  }
  onSelectEnd: (e: SelectionEvent) => void
}

export const useSelectable = ({
  onSelect,
  onReset,
  onRangeSelect,
  onRangeDeselect,
  onMultiSelect,
}: SelectableOptions) => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { data } = useCADQuery()
  const { steps } = useOperationSteps()
  const gltf = data?.gltf

  const selected = useCADPageStore(useShallow((state) => state.selectedParts))
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const pushSelectedPart = useCADPageStore(
    useShallow((state) => state.pushSelectedPart),
  )

  const getAllAddedParts = useCallback(() => {
    return Array.from(new Set(steps.flatMap((step) => step.assembly_group_ids)))
  }, [steps])

  return {
    size: selected.length,
    selected,
    select: (uuid: string) => pushSelectedPart(uuid),
    reset: () => setState({ selectedParts: [] }),
    isSelected: (uuid: string) => selected.includes(uuid),
    handlers: (uuid: string, options?: { object?: Object3D; step?: Step }) => ({
      /**
       *
       * onSelectHover - Highlights the part on hover.
       */
      onSelectHover: (e: SelectionEvent) => {
        e.stopPropagation()
        if (gltf) {
          const selectFromCAD = getCadPageState().operationStep?.selectFromCad
          const partIsAdded = getAllAddedParts().includes(uuid)

          if (selectFromCAD && partIsAdded) {
            document.body.style.cursor = 'not-allowed'
            setCadPageState({ highlightedPartUUID: uuid })
            return
          }

          if (!selected.includes(uuid)) {
            document.body.style.cursor = 'pointer'
            const color = selectFromCAD ? HIGHLIGHT_PURPLE : HIGHLIGHT_BLUE
            gltf.highlightPart(uuid, color)
            setCadPageState({ highlightedPartUUID: uuid })
          }
        }
      },
      /**
       *
       * onSelectStart - Selects a part on mouse/pointer down
       */
      onSelectStart: (e: SelectionEvent) => {
        e.stopPropagation()

        const rightClick = e.button === 2
        const shiftClick = e.shiftKey
        const commandKey = e.metaKey || e.ctrlKey || shiftClick

        const state = getCadPageState()

        const selected = state.selectedParts
        const selectFromCad = state.operationStep?.selectFromCad
        const exploding = state.explosionsState === ExplosionsState.ENABLED
        const partIsAdded = getAllAddedParts().includes(uuid)

        if (selectFromCad && partIsAdded) return

        if (commandKey && !selectFromCad) {
          if (selected.includes(uuid)) {
            setState({ selectedParts: selected.filter((id) => id !== uuid) })
            if (typeof onRangeDeselect === 'function') {
              onRangeDeselect(uuid, options?.object)
            }
          } else {
            if (selected.length === 1 && typeof onRangeSelect === 'function') {
              onRangeSelect([...new Set([...selected, uuid])])
            }
            if (selected.length > 1 && typeof onMultiSelect === 'function') {
              onMultiSelect(uuid)
            }
            pushSelectedPart(uuid)
          }

          return
        }

        if (selected.length === 1 && selected[0] === uuid && !rightClick) {
          if (!selectFromCad && !exploding) setState({ selectedParts: [] })
          if (typeof onReset === 'function') {
            onReset(uuid, options?.object)
          }

          return
        }

        if (!rightClick) {
          if (!selectFromCad) setState({ selectedParts: [uuid] })
          if (typeof onSelect === 'function') {
            onSelect(uuid, options)
          }

          return
        }

        // #TODO: Implement right click
        // if (rightClick && selected.length === 0) {
        //   setState({ selectedParts: [uuid] })
        //   if (typeof onSelect === 'function') {
        //     onSelect(uuid, options)
        //   }
        //   return
        // }
      },
    }),
    /**
     *
     * onSelectEnd - Highlights the selected parts on mouse/pointer up.
     */
    onSelectEnd: useCallback(
      (e: SelectionEvent) => {
        e.stopPropagation()

        if (
          !gltf ||
          !assemblyTree ||
          getCadPageState().isRotating ||
          getCadPageState().isDragging
        )
          return

        const selected = getCadPageState().selectedParts
        const activeOperationStep = getCadPageState().operationStep
        const selectFromCAD = Boolean(activeOperationStep?.selectFromCad)
        const colorMap = getCadPageState().colorMap
        gltf.unhighlightParts(colorMap)

        document.body.style.cursor = 'default'

        setState({ highlightedPartUUID: null })

        if (selected.length > 0) {
          selected.forEach((uuid) => {
            const highlightColor = selectFromCAD
              ? HIGHLIGHT_PURPLE
              : HIGHLIGHT_BLUE

            const node = assemblyTree.nodes.find((n) => n.uuid === uuid)
            if (node) {
              gltf.highlightPart(node.instance, highlightColor)
            }
          })
        }
      },
      [setState, getCadPageState, assemblyTree, gltf],
    ),
  }
}
