import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDocumentState } from '@/state'
import { DocumentTemplate } from '../DocumentTemplate'
import { PartsTable } from '../PartsTable'
import { ToolsTable } from '../ToolsTable'
import type { DocumentPage, View } from '@/lib/api/client'
import { useDocumentPageQuery } from '../../queries'
import { useAnnotationsQuery } from '../Annotations/queries'
import {
  useListImageTemplates,
  useListNotes,
  useGenericTables,
  useListViewsTemplates,
  useManualPartsTables,
  useAutoPartsTable,
  useManualToolsTables,
  useAutoToolsTable,
  useListLabels,
} from '@/services/hooks/template_attributes'
import { useListImages } from '@/services/queries/images'
import { OperationNotesEditor } from '../OperationNotesEditor/OperationNotesEditor'
import DraggableImage, { ImageTypeEnum } from '../DraggableImage'
import { AnnotationsContainer } from '../Annotations/AnnotationsContainer'
import { cleanAndFilterViews } from '@/utils/document_preview'
import { cn, isEmptyOrSpaces } from '@/utils'
import GenericTable from '../GenericTable'
import { useStepBOM } from './hooks/useBOM'
import { OperationLabel } from '../OperationLabel'
import { EmptyDocumentIcon } from '@/components/icons/EmptyDocumentIcon'
import { Button } from '@/components/ui/button'
import { useLocation } from 'wouter'
import { useDocumentTemplateFlag } from '@/featureFlags/useDocumentTemplateFlag'
import { OperationViews } from './OperationViews'
import { OperationImages } from './OperationImages'
import { OperationNotes } from './OperationNotes'
import { useUpdateDocumentPage } from '@/services/queries/document_pages'

const OPERATION_DOCUMENTS_OFFSET = 1000000
const EMPTY_STATE_BANNER_DISMISS_KEY = 'empty_state_banner_dismissed'

export const OperationDocument = ({
  documentPage,
  views,
  versionName,
  createdAt,
  orderNumber,
  isReadOnly,
  isLatestDocumentVersion,
}: {
  documentPage: DocumentPage
  views: View[]
  versionName: string
  createdAt: string
  orderNumber: number | string
  isReadOnly: boolean
  isLatestDocumentVersion?: boolean
}) => {
  const {
    data: { isEnabled: isDocumentTemplateFlagEnabled },
  } = useDocumentTemplateFlag()
  const { data: docData } = useDocumentPageQuery()
  const { isAddingAnnotation } = useAnnotationsQuery()
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const partsInOperation = useStepBOM({
    documentPageId: documentPage.id,
  })
  const { data: images } = useListImages({
    documentVersionId: documentPage.document_version,
  })
  const notesTemplates = useListNotes(documentPage.id)
  const labelTemplates = useListLabels(documentPage.id)

  const imageTemplates = useListImageTemplates(documentPage.id)
  const viewTemplates = useListViewsTemplates(documentPage.id)

  const genericTables = useGenericTables(documentPage.id)
  const manualPartsTables = useManualPartsTables(documentPage.id)
  const autoPartsTable = useAutoPartsTable(documentPage.id)
  const manualToolsTables = useManualToolsTables(documentPage.id)
  const autoToolsTable = useAutoToolsTable(documentPage.id)
  const [isEmptyStateBannerDismissed, setIsEmptyStateBannerDismissed] =
    useState(false)

  const { mutate: updateOperation } = useUpdateDocumentPage()

  const unselectElement = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  useEffect(() => {
    setIsEmptyStateBannerDismissed(
      !!localStorage.getItem(EMPTY_STATE_BANNER_DISMISS_KEY),
    )
  }, [])

  useEffect(() => {
    if (selectedElement) {
      document.addEventListener('click', unselectElement)
    } else {
      document.removeEventListener('click', unselectElement)
    }

    return () => {
      document.removeEventListener('click', unselectElement)
    }
  }, [unselectElement, selectedElement])

  const imageIds = useMemo(
    () =>
      imageTemplates.map(
        (imageTemplate) => imageTemplate.template_values.imageId,
      ),
    [imageTemplates],
  )
  const documentImages = useMemo(
    () =>
      (images || []).filter(
        (image) => imageIds.findIndex((id) => id === image.id) >= 0,
      ),
    [imageIds, images],
  )

  const relativeImages = cleanAndFilterViews({
    viewData: views as View[],
    viewsTemplates: viewTemplates.map((template) => template.template_values),
    documentPageId: documentPage.id as string,
  })

  const absoluteImages = viewTemplates
    .filter((template) => template.template_values.imagePosition === 'absolute')
    .map((template) => {
      const viewId = template.template_values.viewId
      const view = views.find((v) => v.id === viewId)
      return view
    })
    .filter((view) => view !== undefined)

  const documentOrder = useMemo(() => {
    const [whole, ...rest] = orderNumber.toString().split('.')

    if (!rest.length) return Number(whole)

    return Number(
      `${whole}.${rest.reduce((accum, num) => accum + Number(num), 0)}`,
    )
  }, [orderNumber])

  const shouldRenderEmptyDocumentBanner = useMemo(
    () =>
      !isEmptyStateBannerDismissed &&
      Number(orderNumber) === 1 &&
      partsInOperation.length === 0 &&
      manualPartsTables.length === 0 &&
      manualToolsTables.length === 0 &&
      genericTables.length === 0 &&
      absoluteImages.length === 0 &&
      relativeImages.filter((img: any) => img.type !== 'empty').length === 0 &&
      notesTemplates.length === 0 &&
      labelTemplates.length === 0 &&
      documentImages.length === 0,
    [
      partsInOperation,
      manualPartsTables,
      manualToolsTables,
      genericTables,
      absoluteImages,
      relativeImages,
      notesTemplates,
      labelTemplates,
      documentImages,
      isEmptyStateBannerDismissed,
      orderNumber,
    ],
  )

  const shouldRenderNoViewsBanner = useMemo(
    () =>
      !isEmptyStateBannerDismissed &&
      Number(orderNumber) === 1 &&
      !shouldRenderEmptyDocumentBanner &&
      absoluteImages.length === 0 &&
      relativeImages.filter((img: any) => img.type !== 'empty').length === 0 &&
      documentImages.length === 0,
    [
      relativeImages,
      absoluteImages,
      documentImages,
      shouldRenderEmptyDocumentBanner,
      isEmptyStateBannerDismissed,
      orderNumber,
    ],
  )

  const onDismissEmptyStateBanner = useCallback(() => {
    setIsEmptyStateBannerDismissed(true)
    localStorage.setItem(EMPTY_STATE_BANNER_DISMISS_KEY, 'true')
  }, [])

  if (
    !docData ||
    !docData.project ||
    !docData.version ||
    !docData.documentPages
  ) {
    return null
  }

  return (
    <DocumentTemplate
      id={`${documentPage.id}`}
      title={
        isEmptyOrSpaces(documentPage.name)
          ? `Operation ${orderNumber}`
          : documentPage.name
      }
      onEditTitle={(title) => {
        updateOperation({
          documentPageId: documentPage.id as string,
          values: {
            name: title,
            template_values: documentPage.template_values,
            parent_id: documentPage.parent as string,
            assembly_group_id: documentPage.assembly_group_id as string,
          },
        })
      }}
      createdAt={createdAt}
      versionName={versionName}
      isLatestDocumentVersion={isLatestDocumentVersion}
      order={OPERATION_DOCUMENTS_OFFSET + documentOrder}
      documentPageId={documentPage.id as string}
      testId={`operation-page-${orderNumber}-container`}
    >
      <AnnotationsContainer documentPage={documentPage} isReadOnly={isReadOnly}>
        {shouldRenderEmptyDocumentBanner && (
          <EmptyDocumentBanner
            isReadOnly={isReadOnly}
            pageId={documentPage.id as string}
            projectId={docData.project.id}
            documentId={docData.doc?.id as string}
            onDismiss={onDismissEmptyStateBanner}
          >
            <div className="text-center text-primary-40 w-[450px]">
              <h4 className="mb-2 mt-1 font-medium text-lg">
                Link this operation to CAD
              </h4>
              <p className="text-sm">
                Drag part(s) from the Assembly Tree to the Operation step. Your
                Parts BOM will update to reflect that link.
              </p>
            </div>
          </EmptyDocumentBanner>
        )}
        {partsInOperation.length > 0 && (
          <div className="flex justify-between">
            <PartsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              partsTableTemplate={autoPartsTable}
            />
            <ToolsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              toolsTableTemplate={autoToolsTable}
            />
          </div>
        )}
        <div>
          {manualPartsTables.map((tableTemplate) => (
            <PartsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              partsTableTemplate={tableTemplate}
              key={tableTemplate.id}
            />
          ))}
          {manualToolsTables.map((tableTemplate) => (
            <ToolsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              toolsTableTemplate={tableTemplate}
              key={tableTemplate.id}
            />
          ))}
          {manualPartsTables.length > 0 && autoPartsTable && (
            <PartsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              partsTableTemplate={autoPartsTable}
            />
          )}
          {manualToolsTables.length > 0 && autoToolsTable && (
            <ToolsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              toolsTableTemplate={autoToolsTable}
            />
          )}
        </div>
        <div>
          {genericTables.map((table) => (
            <GenericTable
              key={table.id}
              isReadOnly={isReadOnly}
              documentPage={documentPage}
              tableId={table.id as string}
              tableTemplate={table}
            />
          ))}
        </div>
        {shouldRenderNoViewsBanner ? (
          <EmptyDocumentBanner
            isReadOnly={isReadOnly}
            buttonSize={24}
            pageId={documentPage.id as string}
            projectId={docData.project.id}
            documentId={docData.doc?.id as string}
            onDismiss={onDismissEmptyStateBanner}
          >
            <div className="text-center text-primary-40 w-[450px]">
              <h4 className="mb-2 mt-1 font-normal text-md">
                Add screenshots from CAD view
              </h4>
            </div>
          </EmptyDocumentBanner>
        ) : null}
        {isDocumentTemplateFlagEnabled && (
          <>
            <OperationViews
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              views={views}
            />
            <OperationImages
              documentPage={documentPage}
              isReadOnly={isReadOnly}
            />
            <OperationNotes
              documentPage={documentPage}
              isReadOnly={isReadOnly}
            />
          </>
        )}

        {!isDocumentTemplateFlagEnabled && (
          <div
            className="flex flex-1 justify-between"
            style={{
              maxHeight: 360,
            }}
          >
            <div className="flex flex-col gap-2 flex-1">
              <div
                className="flex gap-2"
                style={{
                  height: '50%',
                }}
              >
                {relativeImages.map((view, i) => {
                  const viewId = view.id
                  const downloadUrl = view.download_url
                  const userFacingDownloadUrl = `${view.name}.jpg`
                  const imageTemplates = viewTemplates.filter(
                    (template) => template.template_values.viewId === viewId,
                  )

                  if (view.type === 'empty') {
                    return (
                      <div
                        className={cn('flex flex-col flex-1', {
                          absolute: i >= 2,
                        })}
                        key={`${i}-${view.assembly_group_id}`}
                        style={{
                          maxWidth: '49%',
                        }}
                      />
                    )
                  }

                  return (
                    downloadUrl &&
                    !!imageTemplates.length &&
                    imageTemplates.map((imageTemplate) => (
                      <div
                        key={viewId}
                        className={cn(
                          'flex flex-col justify-between top-0 left-0 flex-1',
                          {
                            [imageTemplate.template_values.imagePosition ||
                            'relative']: true,
                          },
                        )}
                        style={{
                          maxWidth: '49%',
                          maxHeight: 180,
                        }}
                      >
                        <DraggableImage
                          documentPageId={documentPage?.id as string}
                          userFacingDownloadUrl={userFacingDownloadUrl}
                          isAddingAnnotation={isAddingAnnotation}
                          downloadUrl={downloadUrl}
                          imageTemplate={imageTemplate as any}
                          type={ImageTypeEnum.View}
                          imageId={viewId}
                          isReadOnly={isReadOnly}
                        />
                      </div>
                    ))
                  )
                })}
                {viewTemplates
                  .filter(
                    (template) =>
                      template.template_values.imagePosition === 'absolute',
                  )
                  .map((template) => {
                    const viewId = template.template_values.viewId
                    const view = views.find((v) => v.id === viewId)
                    const downloadUrl = view?.download_url
                    const userFacingDownloadUrl = `${view?.name}.jpg`

                    return (
                      downloadUrl && (
                        <div
                          key={viewId}
                          className="flex flex-col justify-between top-0 left-0 flex-1 absolute"
                          style={{
                            maxWidth: '49%',
                            maxHeight: 180,
                          }}
                        >
                          <DraggableImage
                            documentPageId={documentPage?.id as string}
                            userFacingDownloadUrl={userFacingDownloadUrl}
                            isAddingAnnotation={isAddingAnnotation}
                            downloadUrl={downloadUrl}
                            imageTemplate={template as any}
                            type={ImageTypeEnum.View}
                            imageId={viewId}
                            isReadOnly={isReadOnly}
                          />
                        </div>
                      )
                    )
                  })}
              </div>
              <div className="flex gap-2 flex-1" style={{ height: '50%' }}>
                {notesTemplates.map((note) => (
                  <div
                    className={cn('flex flex-1 max-w-[50%]', {
                      'max-w-[50%]':
                        note.template_values.notesPosition === 'relative',
                      [note.template_values.notesPosition || 'relative']: true,
                      'top-0':
                        note.template_values.notesPosition === 'absolute',
                      'left-0':
                        note.template_values.notesPosition === 'absolute',
                    })}
                    key={note.id}
                  >
                    <OperationNotesEditor
                      documentId={docData.documentId ?? ''}
                      projectId={docData?.project.id ?? ''}
                      documentPageId={documentPage.id ?? ''}
                      isAddingAnnotation={isAddingAnnotation}
                      isReadOnly={isReadOnly}
                      type="note"
                      notesTemplate={note}
                    />
                  </div>
                ))}
              </div>
              <div className="flex gap-2 flex-1">
                {labelTemplates.map((label) => (
                  <OperationLabel
                    documentId={docData.documentId ?? ''}
                    projectId={docData?.project.id ?? ''}
                    documentPageId={documentPage.id ?? ''}
                    isAddingAnnotation={isAddingAnnotation}
                    isReadOnly={isReadOnly}
                    labelTemplate={label}
                    key={label.id}
                  />
                ))}
              </div>
            </div>
            {documentImages.map((image) => {
              const downloadUrl = image.download_url as string
              const userFacingDownloadUrl = `${image.name}.jpg`
              const templates = imageTemplates.filter(
                (template) => template.template_values.imageId === image.id,
              )

              if (templates.length) {
                return templates.map((imageTemplate) => (
                  <div
                    key={image.id}
                    className="flex flex-1 flex-col justify-between space-y-2 absolute top-0 left-0 h-full"
                    style={{
                      maxWidth: '49%',
                      maxHeight: 180,
                    }}
                  >
                    <DraggableImage
                      documentPageId={documentPage?.id as string}
                      userFacingDownloadUrl={userFacingDownloadUrl}
                      isAddingAnnotation={isAddingAnnotation}
                      downloadUrl={downloadUrl}
                      imageTemplate={imageTemplate as any}
                      type={ImageTypeEnum.Image}
                      isReadOnly={isReadOnly}
                    />
                  </div>
                ))
              }
            })}
          </div>
        )}
      </AnnotationsContainer>
    </DocumentTemplate>
  )
}

type EmptyDocumentBannerProps = {
  isReadOnly?: boolean
  children: React.ReactNode
  buttonSize?: number
  pageId: string
  projectId: string
  documentId: string
  onDismiss?: () => void
}
const EmptyDocumentBanner = ({
  isReadOnly,
  children,
  buttonSize,
  pageId,
  projectId,
  documentId,
  onDismiss,
}: EmptyDocumentBannerProps) => {
  const [, setLocation] = useLocation()
  const buttonWidthClass = useMemo(
    () => (buttonSize ? `w-${buttonSize}` : ''),
    [buttonSize],
  )
  const buttonHeightClass = useMemo(
    () => (buttonSize ? `h-${buttonSize}` : ''),
    [buttonSize],
  )

  const pageLink = useMemo(
    () => `/p/${projectId}/document/${documentId}/cad?stepId=${pageId}`,
    [projectId, documentId, pageId],
  )

  return !isReadOnly ? (
    <div
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="absolute flex flex-col items-center"
    >
      <EmptyDocumentButton
        className={`${buttonWidthClass} ${buttonHeightClass}`}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setLocation(pageLink)
        }}
      />
      {children}
      <div className="flex items-center content-center mt-2">
        <Button
          variant="outline"
          className="bg-slate-200 text-slate-500 hover:text-slate-400 rounded-2xl px-2 py-0.5"
          onClick={onDismiss}
        >
          Dismiss
        </Button>
      </div>
    </div>
  ) : null
}

const EmptyDocumentButton = ({
  className,
  onClick,
}: {
  className?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className="h-auto hover:bg-transparent"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <EmptyDocumentIcon
        className={className}
        strokeWidth={isHovering ? '2' : '1'}
      />
    </Button>
  )
}
