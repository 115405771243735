import { useCallback } from 'react'

import { useCADPageStore } from '@/state/cad'
import { getDescendants } from '@/state/utils/assembly'
import { useOperationSteps } from '../queries/operation_steps'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'

export const useGetInclusiveGroupIds = () => {
  const { isLoading, steps } = useOperationSteps()
  const operationStep = useCADPageStore((state) => state.operationStep)

  if (isLoading || !operationStep) return null
  const activeStep = steps.find((step) => step.id === operationStep.stepId)
  const stepIsActive = Boolean(operationStep?.isActive)

  if (!activeStep || !stepIsActive) return null
  const stepNumber = activeStep?.order_number

  return [
    ...new Set(steps.slice(0, stepNumber).flatMap((s) => s.assembly_group_ids)),
  ]
}

export const useGetActiveStepInclusiveGroupIds = () => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const operationStep = useCADPageStore((state) => state.operationStep)
  const activeStep = steps.find((step) => step.id === operationStep?.stepId)
  const stepNumber = activeStep?.order_number || 0

  const inclusiveGroupIds = Array.from(
    new Set(steps.slice(0, stepNumber).flatMap((s) => s.assembly_group_ids)),
  )

  const getActiveStepInclusiveGroupAndDescendantIds = useCallback(() => {
    if (!assemblyTree || !inclusiveGroupIds) return []

    return Array.from(
      new Set([
        ...inclusiveGroupIds,
        ...inclusiveGroupIds.flatMap((id: string) => {
          const treeNode = assemblyTree.nodes.find((n) => n.uuid === id)
          return treeNode
            ? getDescendants(assemblyTree, treeNode).map((n) => n.uuid)
            : []
        }),
      ]),
    )
  }, [assemblyTree, inclusiveGroupIds])

  return {
    isLoading: isLoadingSteps,
    data: { inclusiveGroupIds },
    getActiveStepInclusiveGroupAndDescendantIds,
  }
}
