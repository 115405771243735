import { useCallback, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { EyeIcon } from '@heroicons/react/24/solid'
import { EyeSlashIcon } from '@heroicons/react/24/solid'
import { Button } from '@/components/ui/button'
import { useCADPageStore } from '@/state/cad'
import { cn } from '@/utils'
import { useAssemblyTreeQuery } from '../../queries'
import { useGetActiveStepInclusiveGroupIds } from '@/services/hooks/steps'

import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { ASMTreeNode } from '@/state'
import { getAncestors, getDescendants } from '@/state/utils/assembly'

export const HidePartButton = ({
  node,
  gltf,
  isVisible,
}: {
  node: ASMTreeNode
  gltf: GLTFObject
  isVisible: boolean
}) => {
  const {
    isLoading: isAssemblyTreeLoading,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const {
    isLoading: isLoadingInclusiveGroupIds,
    getActiveStepInclusiveGroupAndDescendantIds,
  } = useGetActiveStepInclusiveGroupIds()
  const isLoading = isAssemblyTreeLoading || isLoadingInclusiveGroupIds

  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const operationStep = useCADPageStore(
    useShallow((state) => state.operationStep),
  )

  const isHidden = hiddenParts.includes(node.uuid)

  const descendantNodes = useMemo(() => {
    if (!assemblyTree) return []

    return getDescendants(assemblyTree, node)
  }, [assemblyTree, node])

  const ancestorNodes = useMemo(() => {
    if (!assemblyTree) return []

    return getAncestors(assemblyTree, node)
  }, [assemblyTree, node])

  const toggleHiddenPart = useCallback(() => {
    const shouldShow = !!isHidden

    // For showing, we need to show the node, its ancestors and descendants
    // for hiding, we need to hide the node and its descendants
    const nodes = Array.from(
      new Set(
        shouldShow
          ? [node, ...ancestorNodes, ...descendantNodes]
          : [node, ...descendantNodes],
      ),
    )

    // Set visibility based on whether the part is in the active step
    // or if no step is currently active
    if (
      operationStep &&
      (operationStep.isActive || operationStep.selectFromCad)
    ) {
      const ids = getActiveStepInclusiveGroupAndDescendantIds()
      nodes
        .filter((node) => ids.includes(node.uuid))
        .map((node) => gltf.setVisibility(node.instance, shouldShow))
    } else {
      nodes.map((node) => {
        gltf.setVisibility(node.instance, shouldShow)
      })
    }

    // Always update hidden parts
    const nodeUUIDs = nodes.map((node) => node.uuid)
    setCadPageState({
      hiddenParts: isHidden
        ? hiddenParts.filter((uuid) => !nodeUUIDs.includes(uuid))
        : [...hiddenParts, ...nodeUUIDs],
    })
  }, [
    gltf,
    node,
    isHidden,
    hiddenParts,
    ancestorNodes,
    operationStep,
    setCadPageState,
    descendantNodes,
    getActiveStepInclusiveGroupAndDescendantIds,
  ])

  const Icon = isHidden ? EyeSlashIcon : EyeIcon
  const shouldShowButton = isVisible || isHidden

  if (isLoading) return null

  return (
    <Button
      className="h-auto w-auto p-1 min-h-[24px]"
      variant="ghost"
      onMouseDown={(e) => {
        e.stopPropagation()
        if (shouldShowButton) {
          toggleHiddenPart()
        }
      }}
    >
      <Icon
        className={cn('w-4 h-4', {
          hidden: !shouldShowButton,
          'text-primary-50': !isHidden,
          'text-gray-400': isHidden,
        })}
      />
    </Button>
  )
}
