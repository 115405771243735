import { Box3, Vector3 } from 'three'
import { useEffect, useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { getSelectedObjects } from '@/utils/cad'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { ExplosionsState, useCADPageStore } from '@/state/cad'

export const useExplosionsControls = (gltf?: GLTFObject) => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()

  const setState = useCADPageStore((state) => state.setState)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const selected = useCADPageStore(useShallow((state) => state.selectedParts))
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const explosionsState = useCADPageStore(
    useShallow((state) => state.explosionsState),
  )

  const handleinitialization = useCallback(() => {
    const transformGroup = gltf?.transformGroup
    if (!gltf || !assemblyTree || !transformGroup) return
    const preSelected = getSelectedObjects(
      gltf,
      selected,
      assemblyTree,
      hiddenParts,
    )
    const boundingBox = new Box3()
    preSelected.forEach((obj) => boundingBox.expandByObject(obj))
    const preSelectedCenter = boundingBox.getCenter(new Vector3())
    setState({ point: preSelectedCenter.toArray() })
  }, [gltf, assemblyTree, hiddenParts, selected, setState])

  // on mouse up, cycle the transform group and save explosions state
  const cycleGroup = useCallback(() => {
    const transformGroup = gltf?.transformGroup
    if (transformGroup && assemblyTree) {
      setState({ point: transformGroup.position.toArray() })
      transformGroup.children.forEach((object) => {
        gltf.returnToParent(object)
        gltf.saveObjectState(object)
      })
    }
  }, [gltf, assemblyTree, setState])

  // on selection change, update the transform group objects
  const handleExplosions = useCallback(() => {
    if (gltf && gltf.transformGroup && assemblyTree) {
      gltf.resetTransformGroup()
      const point = useCADPageStore.getState().point
      const selectedParts = useCADPageStore.getState().selectedParts
      gltf.transformGroup.position.fromArray(point)

      const selectedObjects = getSelectedObjects(
        gltf,
        selectedParts,
        assemblyTree,
        hiddenParts,
      )

      selectedObjects.forEach((object) => {
        const node = gltf.objectPartMap.get(object)
        if (node) gltf.highlightPart(node.uuid)
        gltf.saveObjectState(object)
        gltf.transformGroup?.attach(object)
      })
    }
  }, [gltf, hiddenParts, assemblyTree])

  // update explosions state when the transform group is added
  const onGroupAdded = useCallback(() => {
    handleinitialization()

    // Proceed to handle explosions
    const state = useCADPageStore.getState().explosionsState
    if (state === ExplosionsState.INIT_CONTROLS && gltf) {
      setState({ explosionsState: ExplosionsState.ENABLED })
    }
  }, [gltf, setState, handleinitialization])

  // handle drag end
  useEffect(() => {
    const isDragging = gltf?.transformControls?.isDragging
    if (!isDragging && explosionsState === ExplosionsState.DRAGGING) {
      cycleGroup()
      setState({ explosionsState: ExplosionsState.ENABLED })
    }
  }, [
    gltf?.transformControls?.isDragging,
    explosionsState,
    setState,
    cycleGroup,
  ])

  useEffect(() => {
    if (!gltf) return
    const addPartFromCAD =
      useCADPageStore.getState().operationStep?.selectFromCad
    if (explosionsState === ExplosionsState.INIT_CONTROLS) {
      gltf.initTransformGroup(onGroupAdded)
    } else if (explosionsState === ExplosionsState.ENABLED) {
      handleExplosions()
    } else if (
      explosionsState === ExplosionsState.DISABLED &&
      !addPartFromCAD
    ) {
      gltf.cleanupTransformGroup(onGroupAdded)
      gltf.unhighlightParts(colorMap)
    }
  }, [
    gltf,
    selected,
    explosionsState,
    setState,
    handleExplosions,
    onGroupAdded,
    colorMap,
  ])
}
