import { Fragment } from 'react'
import { Link, useParams, useLocation } from 'wouter'
import dayjs from 'dayjs'
import { cn } from '@/utils'
import { CADFileButton } from './CADFileButton'
import { useListProjects, useGetProject } from '@/services/queries/projects'
import { SkeletonTableRows } from '@/pages/ProjectsPage/components/SkeletonTableRows'
import { ProjectsTableActions } from './ProjectsTableActions'
import { DocumentRowActions } from './DocumentRowActions'
import { Button } from '@/components/ui/button'
import Badge from '@/components/ui/badge'
import { useProjectsState } from '@/state'

export const ProjectsTable = () => {
  const [location] = useLocation()
  const { projectId } = useParams<{
    projectId?: string
  }>()

  // Checking location is a workaround because the useParams hook
  // does not update state correctly.
  // ISSUE: https://github.com/molefrog/wouter/pull/479
  const isRootRoutePath = location === '/'
  const shouldGetOneProject = !isRootRoutePath && Boolean(projectId)
  const projectCreatorsFilter = useProjectsState(
    (state) => state.projectCreatorsFilter,
  )

  const {
    data: projectsListData,
    isLoading: isProjectListLoading,
    fetchNextPage,
  } = useListProjects({
    limit: 5,
    offset: 0,
    disabled: shouldGetOneProject,
    creatorIds: projectCreatorsFilter,
  })

  const { data: projectData, isLoading: isProjectLoading } = useGetProject({
    projectId,
    disabled: !shouldGetOneProject,
  })

  const singleProjectPages =
    projectId && !isProjectLoading && projectData
      ? [{ projects: [projectData], totalProjects: 1 }]
      : []

  const isLoading = isProjectListLoading || isProjectLoading

  const pages = !shouldGetOneProject
    ? projectsListData?.pages || []
    : singleProjectPages

  const cads =
    pages.flatMap((page) => {
      return page.projects.flatMap((project) => {
        return project.cads.map((cad) => {
          return cad
        })
      })
    }) ?? []

  const documents =
    pages.flatMap((page) => {
      return page.projects.map((project) => {
        return {
          projectId: project.id,
          title: project.name,
          files: project.documents.map((doc) => {
            const cadFile = cads.find((cad) => cad.id === doc.cad)
            return {
              name: doc.name,
              documentId: doc.id,
              documentType: doc.document_type,
              cadFile: cadFile?.name,
              modified: project.modified,
              created: project.created,
              creator: doc.creator,
            }
          }),
        }
      })
    }) || []

  const totalProjects = pages.length > 0 ? pages[0].totalProjects : 0

  if (documents?.length === 0 && !isLoading) {
    return (
      <div className="flex flex-col items-center text-center  mt-8">
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold">No projects found.</h1>
          <h2 className="text-gray-500">
            Create a new project to get started.
          </h2>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <SkeletonTableRows />
  }

  const headerColumns = ['Documents', 'Creator', 'CAD file', 'Created']

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="text-gray-100">
          <tr>
            {headerColumns.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {documents.map((document, index) => (
            <Fragment key={index}>
              <tr>
                <td
                  colSpan={headerColumns.length}
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  <div className="flex items-center">{document.title}</div>
                </td>
                <td>
                  <ProjectsTableActions projectId={document.projectId} />
                </td>
              </tr>
              {document.files.map((file, idx) => {
                const tdClasses = cn('px-6 py-1 whitespace-nowrap text-sm', {
                  'pb-4': idx === document.files.length - 1,
                })
                return (
                  <tr
                    key={idx}
                    className={cn({
                      'border-b': idx === document.files.length - 1,
                    })}
                  >
                    <td className={cn(tdClasses, 'pl-8')}>
                      <Link
                        href={`/p/${document.projectId}/document/${file.documentId}/cad`}
                      >
                        <span className="text-blue-500">{file.name}</span>
                      </Link>
                    </td>
                    <td className={tdClasses}>
                      {`${file.creator?.first_name || ''} ${file.creator?.last_name || ''}`}
                      {!file.creator?.is_active ? (
                        <Badge label="Deactivated" />
                      ) : null}
                    </td>
                    <td className={tdClasses}>
                      <CADFileButton fileName={file.cadFile || ''} />
                    </td>
                    <td className={tdClasses}>
                      {dayjs(file.created).format('MMM DD, YYYY hh:mma')}
                    </td>
                    <td>
                      <DocumentRowActions
                        documentId={file.documentId}
                        projectId={document.projectId}
                      />
                    </td>
                  </tr>
                )
              })}
            </Fragment>
          ))}
        </tbody>
      </table>
      {totalProjects !== documents.length && (
        <div className="flex justify-center">
          <Button
            size="lg"
            onClick={() => fetchNextPage()}
            className="mt-4 px-10 border-primary-50 text-primary-50 rounded-full"
            variant="outline"
          >
            View more
          </Button>
        </div>
      )}
    </div>
  )
}
