import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { EyeIcon } from '@heroicons/react/24/solid'
import { EyeSlashIcon } from '@heroicons/react/24/solid'
import { Button } from '@/components/ui/button'
import { useCADPageStore } from '@/state/cad'
import { cn } from '@/utils'

import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { ASMTreeNode } from '@/state'
import { useGetActiveStepInclusiveGroupIds } from '@/services/hooks/steps'

export const HideAllPartsButton = ({
  nodes,
  gltf,
}: {
  nodes: ASMTreeNode[]
  gltf: GLTFObject
}) => {
  const { isLoading: isLoading, getActiveStepInclusiveGroupAndDescendantIds } =
    useGetActiveStepInclusiveGroupIds()

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const allPartsHidden = useCADPageStore(
    useShallow((state) => state.hiddenParts.length >= nodes.length / 2),
  )
  const operationStep = useCADPageStore(
    useShallow((state) => state.operationStep),
  )

  const toggleAllParts = useCallback(() => {
    const newHiddenState = !allPartsHidden

    // Set visibility based on whether the part is in the active step
    // or if no step is currently active
    nodes.map((node) => {
      if (operationStep && operationStep.isActive) {
        const ids = getActiveStepInclusiveGroupAndDescendantIds()
        nodes
          .filter((node) => ids.includes(node.uuid))
          .map((node) => gltf.setVisibility(node.instance, !newHiddenState))
      } else {
        gltf.setVisibility(node.instance, !newHiddenState)
      }
    })

    // Always update hidden parts
    setCadPageState({
      hiddenParts: newHiddenState ? nodes.map((node) => node.uuid) : [],
    })
  }, [
    gltf,
    nodes,
    operationStep,
    allPartsHidden,
    setCadPageState,
    getActiveStepInclusiveGroupAndDescendantIds,
  ])

  const Icon = allPartsHidden ? EyeIcon : EyeSlashIcon

  if (isLoading) return null

  return (
    <div className="px-4 ">
      <Button
        className="flex items-center gap-2 h-full px-2 py-0"
        variant="ghost"
        onClick={toggleAllParts}
      >
        <span className="text-sm font-normal">
          {allPartsHidden ? 'Show All' : 'Hide All'}
        </span>
        <Icon
          className={cn('w-4 h-4', {
            'text-primary-50': allPartsHidden,
            'text-gray-400': !allPartsHidden,
          })}
        />
      </Button>
    </div>
  )
}
