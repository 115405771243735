import { Vector3, Object3D } from 'three'
import { TransformControls as DreiTransformControls } from '@react-three/drei'
import { useShallow } from 'zustand/react/shallow'

import { ExplosionsState, useCADPageStore } from '@/state'
import { useEffect, useCallback, useRef, useMemo } from 'react'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { TransformControls } from 'three-stdlib'
import { useThree } from '@react-three/fiber'

export const ExplosionsControls = ({ gltf }: { gltf: GLTFObject }) => {
  const controlsRef = useRef<TransformControls>(null)
  const { raycaster } = useThree()

  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const setExplosion = useCADPageStore(
    useShallow((state) => state.setExplosion),
  )
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const explosionsState = useCADPageStore(
    useShallow((state) => state.explosionsState),
  )
  const enabled = useMemo(
    () =>
      explosionsState === ExplosionsState.ENABLED ||
      explosionsState === ExplosionsState.DRAGGING,
    [explosionsState],
  )

  /*
   * Handler for when the transform controls are used to move an object
   */
  const handleTransform = useCallback(
    (isMouseDown: boolean) => {
      if (!gltf?.transformGroup) return

      const handleObject = (obj: Object3D, isGroup: boolean) => {
        if (isMouseDown) {
          const loadedExplosion = getCadPageState().loadedExplosion
          if (loadedExplosion) {
            setCadPageState({
              loadedExplosion: '',
              explosionsState: ExplosionsState.DRAGGING,
            })
          } else {
            setCadPageState({ explosionsState: ExplosionsState.DRAGGING })
          }
          gltf.unhighlightParts(colorMap)
          gltf.saveObjectState(obj)
        } else {
          const ogPosition = new Vector3().fromArray(
            obj.userData.originalPosition || [0, 0, 0],
          )

          if (isGroup) gltf.returnToParent(obj)

          const node = gltf.objectPartMap.get(obj)
          if (node) {
            setExplosion(
              node.uuid,
              ogPosition.toArray(),
              obj.position.toArray(),
            )
          } else {
            setExplosion(obj.name, ogPosition.toArray(), obj.position.toArray())
          }
        }

        obj.matrixAutoUpdate = isMouseDown
      }

      const children = [...gltf.transformGroup.children]
      children.forEach((child) => handleObject(child, true))
    },
    [gltf, colorMap, getCadPageState, setCadPageState, setExplosion],
  )

  useEffect(() => {
    if (gltf.transformControls) return
    else if (controlsRef.current) {
      gltf.initTransformControls(controlsRef.current)
    }
    return () => gltf.cleanupTransformControls()
  }, [gltf, enabled, raycaster])

  if (!enabled || !gltf.transformGroup) return null

  return (
    <DreiTransformControls
      enabled={enabled}
      ref={controlsRef}
      object={gltf.transformGroup}
      onMouseDown={() => {
        handleTransform(true)
      }}
      onMouseUp={() => {
        handleTransform(false)
      }}
    />
  )
}
