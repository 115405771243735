import { useCallback, useState } from 'react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { ConfirmationDialog } from '@/components/core/ConfirmationDialog'
import { useDeleteProject } from '@/services/queries/projects'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useGetProject } from '@/services/queries/projects'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'

export const ProjectsTableActions = ({
  projectId,
}: {
  projectId?: string | null
}) => {
  const { mutateAsync: deleteProject, isPending } = useDeleteProject()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { data: readOnlyData } = useIsReadOnly()
  const { data: project, isLoading: isProjectLoading } = useGetProject({
    projectId,
  })

  const onCloseDialogHandler = useCallback(() => {
    setTimeout(() => {
      setShowConfirmationModal(false)
    })
  }, [])

  const onShowDialogHandler = useCallback(() => {
    setTimeout(() => {
      setShowConfirmationModal(true)
    })
  }, [])

  if (!project || !projectId || isProjectLoading) {
    return null
  }

  return (
    <>
      <ConfirmationDialog
        title="Are you sure?"
        onConfirm={async () => {
          await deleteProject(project.id as string)
        }}
        isOpen={showConfirmationModal}
        isLoading={isPending}
        closeDialog={onCloseDialogHandler}
      >
        <span className="text-center">
          Once the project &quot;{project.name}&quot; is deleted, it cannot be
          undone.
        </span>
      </ConfirmationDialog>
      {!readOnlyData.isUserReadOnly && (
        <DropdownMenu>
          <DropdownMenuTrigger className="rounded-md hover:bg-gray-100 h-5 w-5">
            <EllipsisHorizontalIcon className="h-full" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={onShowDialogHandler}>
              Delete Project
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}
